import React from 'react';
import AppDrawer from '../AppDrawer';
import { Box, Typography } from '@mui/material';
import { FormParams } from '../../types';
import { useTranslation } from 'react-i18next';

type BookmarkInstructionDrawerProps = {
  formParams: FormParams;
  shortcut: string;
};

const BookmarkInstructionDrawer: React.FC<BookmarkInstructionDrawerProps> = ({
  formParams,
  shortcut
}) => {
  const { t } = useTranslation();

  return (
    <AppDrawer
      title={t('How to bookmark')}
      formParams={formParams}
    >
      <Box sx={{ p: 2 }}>
        <Typography variant="body1" sx={{ mb: 2 }}>
          {t('To bookmark this page, press the following keyboard shortcut:')}
        </Typography>
        <Typography
          variant="h6"
          sx={{
            backgroundColor: 'rgba(0,0,0,0.1)',
            p: 1,
            borderRadius: 1,
            textAlign: 'center'
          }}
        >
          {shortcut}
        </Typography>
      </Box>
    </AppDrawer>
  );
};

export default BookmarkInstructionDrawer;
