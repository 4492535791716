import { DbCollections } from '../shared/common';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { getDbName, mapFromDbFields } from '../shared/mapping';
import { Account } from 'shared-ts/entities';
import { Booking } from 'shared-ts/entities';
import { Experience } from 'shared-ts/entities';
import { User } from 'shared-ts/entities';
import { CreateAccount } from '../firebaseFunctions';

export default class AppService {
  async createAccount(userId: string, name: string) {
    await CreateAccount({
      userId: userId,
      name: name,
    });
  }

  async getAccountById(id: string): Promise<Account> {
    const account = await getDoc(doc(db, DbCollections.accounts, id));
    return mapFromDbFields({ ...account.data(), id: account.id });
  }

  async getBookingById(id: string): Promise<Booking> {
    const booking = await getDoc(doc(db, DbCollections.bookings, id));
    return mapFromDbFields({ ...booking.data(), id: booking.id });
  }

  async getBookingsByAccountId(accountId: string): Promise<Booking[]> {
    // Step 1: Get all experiences by account ID
    const experiences = await this.getExperiencesByAccountId(accountId);
    const experienceIds = experiences.map((exp) => exp.id);

    if (experienceIds.length === 0) {
      return []; // No experiences found for the given account ID
    }

    // Step 2: Get all bookings where experience ID matches any of the experience IDs
    const bookingsRef = collection(db, DbCollections.bookings);
    const q = query(bookingsRef, where(getDbName<Booking>('experienceId'), 'in', experienceIds));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      return []; // No bookings found with the given experience IDs
    }

    return querySnapshot.docs.map((docSnapshot) =>
      mapFromDbFields({ ...docSnapshot.data(), id: docSnapshot.id }),
    );
  }

  async getExperienceById(id: string): Promise<Experience> {
    const experiences = await getDoc(doc(db, DbCollections.experiences, id));
    return mapFromDbFields({ ...experiences.data(), id: experiences.id });
  }

  async getExperienceByLink(link: string): Promise<Experience | null> {
    const experiencesRef = collection(db, DbCollections.experiences);
    const q = query(
      experiencesRef,
      where(getDbName<Experience>('link'), '==', link),
    );
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      return null; // No document found with the given link
    }

    const docSnapshot = querySnapshot.docs[0];
    return mapFromDbFields({ ...docSnapshot.data(), id: docSnapshot.id });
  }

  async getExperiencesByAccountId(accountId: string): Promise<Experience[]> {
    const experiencesRef = collection(db, DbCollections.experiences);
    const q = query(
      experiencesRef,
      where(getDbName<Experience>('accountId'), '==', accountId),
    );
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      return []; // No documents found with the given account ID
    }

    return querySnapshot.docs.map((docSnapshot) =>
      mapFromDbFields({ ...docSnapshot.data(), id: docSnapshot.id }),
    );
  }

  async getUserById(id: string): Promise<User> {
    const user = await getDoc(doc(db, DbCollections.users, id));
    return mapFromDbFields({ ...user.data(), id: user.id });
  }
}
