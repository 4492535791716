import { useMemo } from 'react';
import { FormParams } from '../../types';
import { useTranslation } from 'react-i18next';
import PickerDrawer, { PickerOption } from '../PickerDrawer';

interface Props {
  formParams: FormParams;
  onSelect: (minutes: number) => Promise<{ error?: string }>;
  value?: number;
}

const DailyBookingLimitPickerDrawer: React.FC<Props> = ({
  formParams,
  onSelect,
  value,
}) => {
  const { t } = useTranslation();

  // Generate time durations based on the minute scale
  const options = useMemo(() => {
    const options: PickerOption<number>[] = [];
    for (let days = 1; days <= 90; days++) {
      options.push({
        text: `${t('appointment_count', { count: days })}`.trim(),
        value: days,
      });
    }
    return options;
  }, [t]);

  return (
    <PickerDrawer
      formParams={formParams}
      title={t('Maximum appointments per day')}
      options={options}
      value={value}
      onSelect={onSelect}
      confirmText={t('Select')}
    />
  );
};

export default DailyBookingLimitPickerDrawer;
