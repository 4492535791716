import { Dialog, DialogContent, IconButton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { AppIcon } from '../../components/AppIcon';
import { FormParams } from '../../types';
import { useTranslation } from 'react-i18next';
type CongratsModalProps = {
  formParams: FormParams;
};

export const CongratsModal = ({ formParams }: CongratsModalProps) => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={formParams.open}
      onClose={() => formParams.onClose()}
      PaperProps={{
        sx: {
          backgroundColor: (theme) => theme.palette.background.paper,
          borderRadius: (theme) => theme.shape.borderRadius + 'px',
          padding: '20px',
        },
      }}
    >
      <DialogContent
        sx={{
          textAlign: 'center',
          gap: '16px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <IconButton
          sx={{
            position: 'absolute',
            right: '5px',
            top: '5px',
          }}
          onClick={() => formParams.onClose()}
        >
          <AppIcon
            width={11}
            height={11}
            src="/images/CloseDrawerIcon.svg"
            sx={{ bgcolor: (theme) => theme.palette.text.primary }}
          />
        </IconButton>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            padding: '10px',
            borderRadius: '90px',
            bgcolor: (theme) => `${theme.palette.primary.main}1A`, // Using primary color with 10% opacity
          }}
        >
          <AppIcon
            src="/images/tick_modal.svg"
            width={20}
            height={20}
            sx={{ bgcolor: (theme) => theme.palette.primary.main }}
          />
        </Box>
        <Typography
          variant="h3"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {t('Congratulations!')}
        </Typography>
        <Typography
          variant="body1"
          sx={{ fontSize: '16px', lineHeight: '18.75px', fontWeight: 400 }}
        >
          {t('You have successfully booked an appointment!')}
        </Typography>
      </DialogContent>
    </Dialog>
  );
};
