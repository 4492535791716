import { Box, Divider, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AppDrawer from './AppDrawer';
import { useState, useRef, useEffect } from 'react';
import { AppIcon } from './AppIcon';

export interface PickerOption<T> {
  text: string;
  value: T;
}

interface Props<T> {
  formParams: any;
  title: string;
  description?: string;
  options: PickerOption<T>[];
  value?: T[];
  onSelect: (values: T[]) => Promise<{ error?: string }>;
  confirmText: string;
}

const MultiSelectDrawer = <T extends any>(props: Props<T>) => {
  const [selectedIndices, setSelectedIndices] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const scrollRef = useRef<HTMLDivElement>(null);

  // Update initialization for multiple values
  useEffect(() => {
    if (!props.value || props.options.length === 0) return;

    const indices = props.value
      .map((val) => props.options.findIndex((opt) => opt.value === val))
      .filter((index) => index !== -1);

    setSelectedIndices(indices);
  }, [props.value, props.options]);

  const handleSelect = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const selectedValues = selectedIndices.map(
        (index) => props.options[index].value,
      );
      const result = await props.onSelect(selectedValues);
      if (result.error) {
        setError(result.error);
      } else {
        props.formParams.onClose();
      }
    } catch (err: unknown) {
      setError('An unknown error occurred');
    } finally {
      setIsLoading(false);
    }
  };

  const handleItemClick = (index: number) => {
    setSelectedIndices((prev) => {
      const exists = prev.includes(index);
      return exists ? prev.filter((i) => i !== index) : [...prev, index];
    });
  };

  return (
    <AppDrawer
      formParams={props.formParams}
      title={props.title}
      description={props.description}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          mb: '16px',
        }}
      >
        <Box sx={{ my: '32px' }}>
          <Box
            ref={scrollRef}
            sx={{
              maxHeight: 300,
              overflowY: 'auto',
              '&::-webkit-scrollbar': { display: 'none' },
              scrollSnapType: 'y mandatory',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              px: '16px',
              backgroundColor: (theme) => theme.palette.background.paper,
              borderRadius: (theme) => `${theme.shape.borderRadius}px`,
            }}
          >
            {props.options.map((item, index) => (
              <Box key={item.text} sx={{ width: '100%' }}>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center',
                  }}
                  onClick={() => handleItemClick(index)}
                >
                  <Typography
                    sx={{
                      flexGrow: 1,
                      py: '16px',
                      px: '5px',
                      cursor: 'pointer',
                      scrollSnapAlign: 'center',
                      fontSize: '16px',
                      textAlign: 'left',
                    }}
                  >
                    {item.text}
                  </Typography>
                  {selectedIndices.includes(index) && (
                    <Box sx={{ width: '23px', height: '16px' }}>
                      <AppIcon src="/images/tick.svg" height={'16px'} />
                    </Box>
                  )}
                </Box>
                {index !== props.options.length - 1 && <Divider flexItem />}
              </Box>
            ))}
          </Box>

          {error && (
            <Typography
              sx={{
                color: 'error.main',
                mb: 2,
                textAlign: 'center',
              }}
            >
              {error}
            </Typography>
          )}
        </Box>

        <LoadingButton
          type="submit"
          variant="contained"
          onClick={handleSelect}
          loading={isLoading}
          fullWidth
        >
          {props.confirmText}
        </LoadingButton>
      </Box>
    </AppDrawer>
  );
};

export default MultiSelectDrawer;
