import { gapi } from 'gapi-script';
import React, {
  createContext,
  ReactNode,
  useEffect,
  useState,
  useContext,
  useCallback,
} from 'react';
import { useUserContext } from '../../contexts/UserContext';
import useSetGoogleIntegration from '../../queryHelpers/useSetGoogleIntegration';
import useUpdateAccount from '../../queryHelpers/useUpdateAccount';
import { useGoogleAccessToken } from './useGoogleAccessToken';
import { googleAuthInstance, initGapi } from '../../helpers/gapiHelpers';

(async () => {
  await initGapi(gapi);
})();

interface GoogleClient {
  auth: gapi.auth2.GoogleAuth;
}

interface GoogleClientContextType {
  client: GoogleClient | null;
  // user: gapi.auth2.GoogleUser | null;
  isPending: boolean;
  connect: () => Promise<void>;
  disconnect: () => Promise<void>;
}

export const GoogleClientContext = createContext<
  GoogleClientContextType | undefined
>(undefined);

// New hook to use the GoogleScriptContext
export const useGoogleClient = () => {
  const context = useContext(GoogleClientContext);
  if (!context) {
    throw new Error(
      'useGoogleClient must be used within a GoogleClientProvider',
    );
  }
  return context;
};

const GoogleClientProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [client, setClient] = useState<GoogleClient | null>(null);

  const { user } = useUserContext();
  const useSetGoogleIntegrationMutation = useSetGoogleIntegration();
  const useUpdateAccountMutation = useUpdateAccount();
  const googleAccessToken = useGoogleAccessToken();

  useEffect(() => {
    setClient({ auth: googleAuthInstance() });
  }, []);

  useEffect(() => {
    if (googleAccessToken.isPending) {
      return;
    }

    if (!gapi.client) {
      return;
    }

    gapi.client.setToken({
      access_token: googleAccessToken.accessToken!,
    });
  }, [googleAccessToken.accessToken, googleAccessToken.isPending]);

  const connect = useCallback(async () => {
    try {
      const offlineAccessResponse = await client?.auth?.grantOfflineAccess();
      if (!offlineAccessResponse) {
        throw new Error('Could not get offline access');
      }

      await useSetGoogleIntegrationMutation.mutateAsync({
        accountId: user?.accountId!,
        code: offlineAccessResponse.code,
      });
    } catch (error) {
      console.error('Failed to connect Google Calendar', error);
      throw error;
    }
  }, [client?.auth, useSetGoogleIntegrationMutation, user?.accountId]);

  const disconnect = useCallback(async () => {
    try {
      await useUpdateAccountMutation.mutateAsync({
        accountId: user?.accountId!,
        deleteGoogleAccount: true,
      });
      await client?.auth.disconnect();
    } catch (error) {
      console.error('Failed to disconnect Google Calendar', error);
    }
  }, [client?.auth, useUpdateAccountMutation, user?.accountId]);

  return (
    <GoogleClientContext.Provider
      value={{
        client,
        isPending: googleAccessToken.isPending,
        connect,
        disconnect,
      }}
    >
      {children}
    </GoogleClientContext.Provider>
  );
};

export default GoogleClientProvider;
