import React from 'react';
import { Box, FormHelperText, Typography } from '@mui/material';
import { DateTimeSlotVM } from '../../viewModels';
import { useTimeZone } from '../../contexts/TimeZoneContext';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';

interface TimeSlotSelectorProps {
  slots: DateTimeSlotVM[];
  onChange: (slot: DateTimeSlotVM) => void;
  value: DateTimeSlotVM | null;
  helperText?: string;
  error?: boolean;
}

const TimeSlotBox = styled(Box)<{ selected?: boolean }>(
  ({ theme, selected }) => ({
    padding: '8px 4px',
    borderRadius: `${theme.shape.borderRadius}px`,
    backgroundColor: theme.palette.background.paper,
    cursor: 'pointer',
    border: '1px solid transparent',
    ...(selected && {
      borderColor: theme.palette.primary.main,
    }),
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
    },
  }),
);

const TimeSlotSelector: React.FC<TimeSlotSelectorProps> = (props) => {
  const { timezone } = useTimeZone();
  const { t } = useTranslation();
  const handleClick = (slot: DateTimeSlotVM) => {
    if (!props.onChange) {
      return;
    }
    props.onChange(slot);
  };

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '8px', justifyContent: 'center' }}>
      {props.slots.map((slot) => (
        <TimeSlotBox
          key={`${slot.start.toMillis()}-${slot.end.toMillis()}`}
          selected={
            props.value?.start.hasSame(slot.start, 'minute') &&
            props.value?.end.hasSame(slot.end, 'minute')
          }
          onClick={() => handleClick(slot)}
        >
          <Typography>
            {slot.start.setZone(timezone).toFormat('HH:mm')}
            {'-'}
            {slot.end.setZone(timezone).toFormat('HH:mm')}
          </Typography>
        </TimeSlotBox>
      ))}
      {props.slots.length === 0 && (
        <Typography variant="caption">{t('No available slots')}</Typography>
      )}
      {props.helperText && (
        <FormHelperText error={props.error}>{props.helperText}</FormHelperText>
      )}
    </Box>
  );
};

export default TimeSlotSelector;
