import { collection, addDoc, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { getDbName, mapToDbFields, mapFromDbFields } from '../shared/mapping';
import { WaitlistEntry } from 'shared-ts/entities';
import { DbCollections } from '../shared/common';

export async function isEmailInWaitlist(email: string): Promise<boolean> {
  const waitlistRef = collection(db, DbCollections.waitlist);
  const q = query(waitlistRef, where(getDbName<WaitlistEntry>('email'), '==', email));
  const querySnapshot = await getDocs(q);
  return !querySnapshot.empty;
}

export async function addToWaitlist(email: string): Promise<void> {
  const waitlistRef = collection(db, DbCollections.waitlist);
  const entry: WaitlistEntry = {
    email,
    timestamp: new Date().getTime(),
    allowed: false,
  };
  await addDoc(waitlistRef, mapToDbFields(entry));
}

export async function isEmailAllowed(email: string): Promise<boolean> {
  const waitlistRef = collection(db, DbCollections.waitlist);
  const q = query(waitlistRef, where(getDbName<WaitlistEntry>('email'), '==', email));
  const querySnapshot = await getDocs(q);

  if (querySnapshot.empty) {
    return false;
  }

  const entry = mapFromDbFields<WaitlistEntry>(querySnapshot.docs[0].data());
  return entry.allowed === true;
}
