import React, { useState, useEffect, useRef } from 'react';
import List from '@mui/material/List';
import { Box, ListItemButton, SxProps, Theme } from '@mui/material';
import { Times } from './TimeSelector.helpers';
import { timeToString } from '../../../helpers/timeHelpers';

interface TimeSelectorProps {
  sx?: SxProps<Theme>;
  value?: number;
  minTime?: number;
  onChange?: (value: number) => void;
}

const TimeSelector: React.FC<TimeSelectorProps> = ({
  sx,
  value,
  minTime = 0,
  onChange,
}) => {
  const [selectedTime, setSelectedTime] = useState<number | null>(
    value || null,
  );
  const selectedTimeRef = useRef<HTMLDivElement>(null);
  const prevValueRef = useRef(value);
  const isFirstScrollRef = useRef(true);

  useEffect(() => {
    if (prevValueRef.current !== value) {
      setSelectedTime(value!);
      prevValueRef.current = value;
    }
  }, [value]);

  useEffect(() => {
    if (selectedTimeRef.current) {
      selectedTimeRef.current.scrollIntoView({
        block: 'center',
      });
    }
  }, []);

  useEffect(() => {
    if (selectedTimeRef.current) {
      selectedTimeRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      isFirstScrollRef.current = false;
    }
  }, [selectedTime]);

  const handleListItemClick = (time: number) => {
    setSelectedTime(time);
    if (onChange) {
      onChange(time);
    }
  };

  return (
    <Box sx={{ ...sx }}>
      <Box
        sx={{
          maxHeight: '200px',
          overflow: 'auto',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          position: 'relative',
          zIndex: 1,
        }}
      >
        <List>
          {Times.map((time, index) => (
            <ListItemButton
              key={index}
              selected={selectedTime === time}
              onClick={() => handleListItemClick(time)}
              ref={selectedTime === time ? selectedTimeRef : null}
              disabled={time < minTime && index !== Times.length - 1}
              sx={{
                textAlign: 'center',
                justifyContent: 'center',
                mx: '8px'
              }}
            >
              {timeToString(time)}
            </ListItemButton>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default TimeSelector;
