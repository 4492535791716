import React from 'react';
import { TextField, Grid } from '@mui/material';
import { Controller, UseFormReturn } from 'react-hook-form';
import PhoneInput from '../PhoneInput';
import { useTranslation } from 'react-i18next';
import { Field } from '../../styled/Field';
import { BookingVM } from '../../viewModels';

export type BookingClientFormInputs = Pick<BookingVM, 'name' | 'phone' | 'email'>;

interface BookingClientEditorProps {
  form: UseFormReturn<BookingClientFormInputs>;
}

const BookingClientEditor: React.FC<BookingClientEditorProps> = (props) => {
  const { t } = useTranslation();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Field
          label={t('Name')}
          errorMessage={props.form.formState.errors.name?.message}
        >
          <TextField
            size="small"
            error={!!props.form.formState.errors.name}
            {...props.form.register('name')}
            fullWidth
          />
        </Field>
      </Grid>
      <Grid item xs={12}>
        <Field
          label={t('Email')}
          errorMessage={props.form.formState.errors.email?.message}
        >
          <Controller
            name="email"
            control={props.form.control}
            render={() => (
              <TextField
                size="small"
                error={!!props.form.formState.errors.email}
                {...props.form.register('email')}
                fullWidth
              />
            )}
          />
        </Field>
      </Grid>
      <Grid item xs={12}>
        <Field
          label={t('Phone')}
          errorMessage={props.form.formState.errors.phone?.message}
        >
          <Controller
            name="phone"
            control={props.form.control}
            render={({ field }) => (
              <PhoneInput
                value={field.value}
                onChange={(value) => field.onChange(value)}
                error={!!props.form.formState.errors.phone}
              />
            )}
          />
        </Field>
      </Grid>
    </Grid>
  );
};

export default BookingClientEditor;
