import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormParams } from '../../types';
import TransparentPickerDrawer from '../TransparentPickerDrawer';
import { useUserContext } from '../../contexts/UserContext';

interface Props {
  formParams: FormParams;
}

const LogoutConfirmationDrawer: React.FC<Props> = ({ formParams }) => {
  const { t } = useTranslation();
  const { logout } = useUserContext();

  const handleLogout = async (_: string) => {
    await logout();
    return {};
  };

  return (
    <TransparentPickerDrawer
      formParams={formParams}
      description={t('Are you sure you want to logout?')}
      options={[{ text: t('Logout'), value: 'logout', isError: true }]}
      onSelect={handleLogout}
    />
  );
};

export default LogoutConfirmationDrawer;
