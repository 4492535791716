import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { Outlet, useOutletContext, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
// Import icons for the bottom navigation
import { AdaptiveArrowBackIcon } from '../../components/AdaptiveArrowBackIcon';
import {
  AdminSidebar,
  DESKTOP_SIDEBAR_WIDTH,
} from '../../components/AdminSidebar';
import { AppDrawerProvider } from '../../components/AppDrawerContext';
import { AdminBottomNavigation } from '../../components/AdminBottomNavigation';

export type TopAppBarActionItem = {
  icon: React.ReactNode;
  label: string;
  onClick: () => void;
};

export type TopAppBarProps = {
  title: string;
  backButtonPath?: string;
  actionItems?: TopAppBarActionItem[];
  fab?: BottomAppBarFab;
  hide?: boolean;
};

export type BottomAppBarFab = {
  title: string;
  icon: React.ReactNode;
  onClick: () => void;
};

export type BottomAppBarProps = {
  fab?: BottomAppBarFab;
};

export type AppBarsProps = {
  TopAppBar?: TopAppBarProps;
  BottomAppBar?: BottomAppBarProps;
};

export type AdminOutletContext = {
  setAppBars: (props: AppBarsProps) => void;
};

export function useAdminOutletContext() {
  return useOutletContext<AdminOutletContext>();
}

export default function AdminLayout() {
  const containerRef = useRef(null);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    if (containerRef.current) {
      const { offsetWidth, offsetHeight } = containerRef.current;
      console.log(`Container size: ${offsetWidth} x ${offsetHeight}`);
    }
  }, []);

  const [appBarsProps, setAppBarProps] = useState<AppBarsProps | null>(null);

  const setAppBars = (props: AppBarsProps) => {
    setAppBarProps(props);
  };

  const navigate = useNavigate();

  return (
    <AppDrawerProvider value={{}}>
      <Box
        ref={containerRef}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: 'calc(var(--vh, 1vh) * 100)',
          width: '100%',
          paddingTop: !isDesktop ? 'env(safe-area-inset-top)' : 0,
          paddingBottom: !isDesktop ? 'env(safe-area-inset-bottom)' : 0,
          boxSizing: 'border-box',
        }}
      >
        {!appBarsProps?.TopAppBar?.hide && (
          <AppBar
            component="nav"
            variant="elevation"
            elevation={0}
            sx={{
              backgroundColor: (theme) =>
                theme.components?.MuiAppBar?.defaultProps?.style
                  ?.backgroundColor,
              paddingTop: 'max(env(safe-area-inset-top), 0px)',
              width: isDesktop
                ? 'calc(100% - ' + DESKTOP_SIDEBAR_WIDTH + 'px)'
                : '100%',
              ml: isDesktop ? DESKTOP_SIDEBAR_WIDTH + 'px' : 0,
            }}
          >
            <Toolbar sx={{ position: 'relative' }}>
              <Box>
                {!!appBarsProps?.TopAppBar?.backButtonPath && (
                  <IconButton
                    color="inherit"
                    aria-label="Back"
                    onClick={() =>
                      navigate(appBarsProps!.TopAppBar!.backButtonPath!)
                    }
                  >
                    <AdaptiveArrowBackIcon />
                  </IconButton>
                )}
              </Box>
              <Typography variant="h3">
                {appBarsProps?.TopAppBar?.title}
              </Typography>
              <Box sx={{ marginLeft: 'auto', display: 'flex' }}>
                {appBarsProps?.TopAppBar?.actionItems?.map((item, index) => (
                  <IconButton
                    key={index}
                    color="inherit"
                    aria-label={item.label}
                    onClick={item.onClick}
                  >
                    {item.icon}
                  </IconButton>
                ))}
              </Box>
            </Toolbar>
          </AppBar>
        )}

        {isDesktop && <AdminSidebar />}

        <Box
          sx={{
            flexGrow: 1,
            overflowY: 'auto',
            ml: isDesktop ? '256px' : '16px',
            mr: '16px',
            mt: {
              xs: appBarsProps?.TopAppBar?.hide ? 0 : 5,
              sm: appBarsProps?.TopAppBar?.hide ? 0 : 6,
            },
            '&::-webkit-scrollbar': {
              display: 'none',
            },
            pt: { xs: 2, sm: 2 },
            // mb: isDesktop ? 0 : '16px',
            // pb: isDesktop ? 0 : 'calc(56px + 16px)',
          }}
        >
          <Box
            sx={{
              pt: 'max(env(safe-area-inset-top), 0px)',
              pb: 'max(env(safe-area-inset-bottom), 16px)',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <AppDrawerProvider
              value={{
                paperProps: {
                  sx: {
                    ml: isDesktop ? DESKTOP_SIDEBAR_WIDTH + 'px' : 0,
                    width: isDesktop
                      ? 'calc(100% - ' + DESKTOP_SIDEBAR_WIDTH + 'px)'
                      : '100%',
                  },
                },
              }}
            >
              <Box component="main">
                <Box sx={{ mb: '16px' }}>
                  <Outlet
                    context={
                      {
                        setAppBars,
                      } satisfies AdminOutletContext
                    }
                  />
                </Box>
              </Box>
            </AppDrawerProvider>
          </Box>
        </Box>

        {!isDesktop && (
          <>
            <AdminBottomNavigation />
          </>
        )}
      </Box>
    </AppDrawerProvider>
  );
}
