import { useMemo } from 'react';
import { FormParams } from '../../types';
import { useTranslation } from 'react-i18next';
import PickerDrawer, { PickerOption } from '../PickerDrawer';

interface DurationPickerDrawerProps {
  formParams: FormParams;
  onSelect: (minutes: number) => Promise<{ error?: string }>;
  value?: number;
}

const DurationPickerDrawer: React.FC<DurationPickerDrawerProps> = ({
  formParams,
  onSelect,
  value,
}) => {
  const { t } = useTranslation();

  // Generate time durations (5 minutes to 24 hours)
  const durations: PickerOption<number>[] = useMemo(() => {
    const durations: PickerOption<number>[] = [];
    for (let hours = 0; hours <= 24; hours++) {
      for (let minutes = 0; minutes < 60; minutes += 5) {
        if (hours === 0 && minutes === 0) continue; // Skip 0 minutes
        const duration = `${hours > 0 ? t('hour_count', { count: hours }) : ''} ${
          minutes > 0 ? t('minute_count', { count: minutes }) : ''
        }`.trim();
        durations.push({ text: duration, value: hours * 60 + minutes });
      }
    }
    return durations;
  }, [t]);

  return (
    <PickerDrawer
      formParams={formParams}
      title={t('Select duration')}
      options={durations}
      value={value}
      onSelect={onSelect}
      confirmText={t('Select')}
    />
  );
};

export default DurationPickerDrawer;
