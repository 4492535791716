import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormParams } from '../types';
import AppErrorButton from './AppErrorButton';
import AppTransparentDrawer from './AppTransparentDrawer';
import { Box, Divider, Typography, useTheme } from '@mui/material';
import toast from 'react-hot-toast';
import { createToastStyle } from '../shared/styles';

interface Props {
  formParams: FormParams;
  onSelect: (value: boolean) => Promise<{ error?: string }>;
  text: string;
}

const YesNoPickerDrawer: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [isLoadingYes, setIsLoadingYes] = useState(false);
  const [isLoadingNo, setIsLoadingNo] = useState(false);
  const handleSelect = async (value: boolean) => {
    if (value) {
      setIsLoadingYes(true);
    } else {
      setIsLoadingNo(true);
    }

    try {
      const result = await props.onSelect(value);
      if (result.error) {
        toast.error(result.error, createToastStyle(theme));
      } else {
        props.formParams.onClose();
      }
    } catch (err: unknown) {
      toast.error(t('An unknown error occurred'), createToastStyle(theme));
    } finally {
      setIsLoadingYes(false);
      setIsLoadingNo(false);
    }
  };

  return (
    <AppTransparentDrawer formParams={props.formParams}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          mb: '16px',
        }}
      >
        <Box
          sx={{
            backgroundColor: (theme) =>
              (theme.components?.MuiCard?.styleOverrides?.root as any)
                .backgroundColor,
            borderRadius: (theme) => `${theme.shape.borderRadius}px`,
          }}
        >
          <Typography
            variant="body1"
            sx={{
              px: '16px',
              py: '16px',
              textAlign: 'center',
            }}
          >
            {props.text}
          </Typography>
          <Divider />
          <AppErrorButton
            text={t('Yes')}
            onClick={() => handleSelect(true)}
            isLoading={isLoadingYes}
            disabled={isLoadingNo}
            fullWidth
            disableTopRadius
            disableBottomRadius
            color={theme.palette.action.active}
          />
          <Divider />
          <AppErrorButton
            text={t('No')}
            onClick={() => handleSelect(false)}
            isLoading={isLoadingNo}
            disabled={isLoadingYes}
            fullWidth
            disableTopRadius
            color={theme.palette.action.active}
          />
        </Box>
        <AppErrorButton
          text={t('Cancel')}
          onClick={() => props.formParams.onClose()}
          color={theme.palette.action.active}
        />
      </Box>
    </AppTransparentDrawer>
  );
};

export default YesNoPickerDrawer;
