import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormParams } from '../../types';
import AppErrorButton from '../AppErrorButton';
import AppTransparentDrawer from '../AppTransparentDrawer';
import { Box, Divider, Typography, useTheme } from '@mui/material';
import { useGoogleClient } from '../googleClientProvider/GoogleClientProvider';
import toast from 'react-hot-toast';
import { createToastStyle } from '../../shared/styles';

interface Props {
  formParams: FormParams;
  onConnectedGoogleCalendar: () => void;
}

const ConnectGoogleCalendarDrawer: React.FC<Props> = ({
  formParams,
  onConnectedGoogleCalendar,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const googleClient = useGoogleClient();

  const handleEnableGoogleCalendar = async () => {
    try {
      await googleClient.connect();
      onConnectedGoogleCalendar();
      formParams.onClose();
    } catch (error) {
      if ((error as any)?.details.code === 'GOOGLE_PERMISSIONS_NOT_GRANTED') {
        toast.error(
          t(
            'You have not granted permission to access your Google Calendar during the connection. Please start again and check the permissions to grant access.',
          ),
          { ...createToastStyle(theme), duration: 10000 },
        );
        return;
      }
      toast.error(
        t('Error connecting Google Calendar. Please try again later.'),
        createToastStyle(theme),
      );
    }
  };

  return (
    <AppTransparentDrawer formParams={formParams}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          mb: '16px',
        }}
      >
        <Box
          sx={{
            backgroundColor: (theme) =>
              (theme.components?.MuiCard?.styleOverrides?.root as any)
                .backgroundColor,
            borderRadius: (theme) => `${theme.shape.borderRadius}px`,
          }}
        >
          <Typography
            variant="body1"
            sx={{
              px: '16px',
              py: '16px',
              textAlign: 'center',
            }}
          >
            {t(
              'Connect your Google Calendar to your account to continue'
            )}
          </Typography>
          <Divider />
          <AppErrorButton
            text={t('Connect Google Calendar')}
            onClick={handleEnableGoogleCalendar}
            fullWidth
            disableTopRadius
            color={theme.palette.action.active}
          />
        </Box>
        <AppErrorButton
          text={t('Cancel')}
          onClick={() => formParams.onClose()}
          color={theme.palette.action.active}
        />
      </Box>
    </AppTransparentDrawer>
  );
};

export default ConnectGoogleCalendarDrawer;
