import React, { useState } from 'react';
import { Box, Button, TextField, Typography, CircularProgress, Alert } from '@mui/material';
import { Navigate, useNavigate } from 'react-router-dom';
import { routes } from '../../routes';
import { useUserContext } from '../../contexts/UserContext';
import { auth } from '../../firebaseConfig';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, AdditionalUserInfo, AuthError, fetchSignInMethodsForEmail } from 'firebase/auth';
import { useTranslation } from 'react-i18next';
import { addToWaitlist, isEmailAllowed, isEmailInWaitlist } from '../../services/waitlistService';
import useCreateAccount from '../../queryHelpers/useCreateAccount';
import toast from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const getFirebaseErrorMessage = (error: AuthError, t: (key: string) => string): string => {
  switch (error.code) {
    case 'auth/user-not-found':
      return t('No account found with this email. Please check your email or sign up.');
    case 'auth/wrong-password':
      return t('Incorrect password. Please try again.');
    case 'auth/invalid-email':
      return t('Please enter a valid email address.');
    case 'auth/email-already-in-use':
      return t('An account with this email already exists. Please login instead.');
    case 'auth/weak-password':
      return t('Password should be at least 6 characters long.');
    case 'auth/too-many-requests':
      return t('Too many failed attempts. Please try again later.');
    case 'auth/network-request-failed':
      return t('Network error. Please check your internet connection.');
    default:
      return error.message;
  }
};

interface LoginFormInputs {
  email: string;
  password: string;
  name?: string;
}

const CustomLoginPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userContext = useUserContext();
  const createAccountMutation = useCreateAccount();
  const [isSignUp, setIsSignUp] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [waitlistMessage, setWaitlistMessage] = useState<string | null>(null);
  const [firebaseError, setFirebaseError] = useState<string | null>(null);
  const [registrationStep, setRegistrationStep] = useState<'email' | 'credentials'>('email');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const formSchema = yup.object().shape({
    email: yup
      .string()
      .email(t('Invalid email'))
      .required(t('Enter email')),
    password: yup
      .string()
      .when('$registrationStep', {
        is: 'credentials',
        then: (schema) => schema.min(6, t('Password should be at least 6 characters long')).required(t('Enter password')),
        otherwise: (schema) => schema.optional(),
      }),
    name: yup
      .string()
      .when('$registrationStep', {
        is: true,
        then: (schema) => schema.required(t('Enter name')),
        otherwise: (schema) => schema.optional(),
      }),
  });

  const form = useForm<LoginFormInputs>({
    defaultValues: {
      email: '',
      password: '',
      name: '',
    },
    resolver: yupResolver(formSchema) as any,
    context: { isSignUp, registrationStep },
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  // Check if running in web browser
  const isWebVersion = typeof window !== 'undefined' && !window.matchMedia('(display-mode: standalone)').matches;

  const handleLogoClick = () => {
    if (isWebVersion) {
      navigate(routes.landing);
    }
  };

  const handleEmailSubmit = async (data: LoginFormInputs) => {
    setIsSubmitted(true);
    setWaitlistMessage(null);
    setFirebaseError(null);
    setIsLoading(true);

    try {
      if (isSignUp) {
        // Check if email is allowed
        const allowed = await isEmailAllowed(data.email);

        if (!allowed) {
          // Check if email is already in waitlist
          const inWaitlist = await isEmailInWaitlist(data.email);

          if (inWaitlist) {
            setWaitlistMessage(t('This email is already on our waitlist. We will notify you when access is opened.'));
            setIsLoading(false);
            return;
          }

          // Add to waitlist
          await addToWaitlist(data.email);
          setWaitlistMessage(t('You have been added to our waitlist. We will notify you when access is opened.'));
          setIsLoading(false);
          return;
        }

        // Check if email already exists
        const signInMethods = await fetchSignInMethodsForEmail(auth, data.email);
        if (signInMethods.length > 0) {
          setFirebaseError(t('An account with this email already exists. Please login instead.'));
          setIsLoading(false);
          return;
        }
      }

      // Email is valid, proceed to credentials step
      setRegistrationStep('credentials');
    } catch (err: any) {
      setFirebaseError(getFirebaseErrorMessage(err, t));
    } finally {
      setIsLoading(false);
    }
  };

  const handleCredentialsSubmit = async (data: LoginFormInputs) => {
    setIsSubmitted(true);
    setFirebaseError(null);
    setIsLoading(true);

    try {
      if (isSignUp) {
        if (!data.name?.trim()) {
          form.setError('name', { message: t('Please enter your name') });
          setIsLoading(false);
          return;
        }

        // Create Firebase user
        const userCredential = await createUserWithEmailAndPassword(auth, data.email, data.password);

        // Login first
        await userContext.login(userCredential.user, {} as AdditionalUserInfo);

        // Create user in the system
        await createAccountMutation.mutateAsync(
          {
            userId: userCredential.user.uid,
            name: data.name.trim(),
          },
          {
            onSuccess: async () => {
              setWaitlistMessage(t('Account created! Please check your email to verify your account.'));
              navigate(routes.admin.bookings);
            },
            onError: (error) => {
              toast.error(t('Error creating account'));
            },
          },
        );
      } else {
        // Sign in
        const userCredential = await signInWithEmailAndPassword(auth, data.email, data.password);
        await userContext.login(userCredential.user, {} as AdditionalUserInfo);
        navigate(routes.admin.bookings);
      }
    } catch (err: any) {
      setFirebaseError(getFirebaseErrorMessage(err, t));
    } finally {
      setIsLoading(false);
    }
  };

  const handleBack = () => {
    setRegistrationStep('email');
    form.clearErrors();
    setIsSubmitted(false);
    setFirebaseError(null);
  };

  return (
    <>
      {userContext.user?.accountId && <Navigate to={routes.admin.bookings} />}
      {!userContext.user && <Navigate to={routes.login} />}

      <Box
        sx={{
          minHeight: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          py: 4,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            maxWidth: '360px',
          }}
        >
          <Box
            component={'img'}
            src="/images/logo.svg"
            sx={{
              height: '150px',
              width: 'auto',
              mb: 4,
              cursor: isWebVersion ? 'pointer' : 'default',
            }}
            alt="Logo"
            onClick={handleLogoClick}
          />

          {!userContext.authPending && !userContext.user && (
            <Box
              component="form"
              noValidate
              onSubmit={form.handleSubmit(
                registrationStep === 'email' ? handleEmailSubmit : handleCredentialsSubmit,
                (errors) => {
                  setIsSubmitted(true);
                  console.log('Form errors:', errors);
                }
              )}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                width: '100%',
                p: 3,
                borderRadius: 2,
                bgcolor: 'background.paper',
                boxShadow: 1,
              }}
            >
              <Typography variant="h5" sx={{ mb: 2, textAlign: 'center' }}>
                {isSignUp ? t('Sign Up') : t('Login')}
              </Typography>

              {firebaseError && (
                <Alert severity="error" sx={{ mb: 2 }}>
                  {firebaseError}
                </Alert>
              )}

              {waitlistMessage && (
                <Alert severity="info" sx={{ mb: 2 }}>
                  {waitlistMessage}
                </Alert>
              )}

              <Box sx={{ width: '100%' }}>
                <Typography
                  variant="body1"
                  sx={{
                    color: 'rgba(0, 0, 0, 0.54)',
                    mb: 1,
                    fontSize: '16px',
                  }}
                >
                  {t('Email')}
                </Typography>
                <TextField
                  type="email"
                  {...form.register('email')}
                  error={isSubmitted && !!form.formState.errors.email}
                  helperText={isSubmitted ? form.formState.errors.email?.message : ''}
                  required
                  fullWidth
                  disabled={isLoading || registrationStep !== 'email'}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      backgroundColor: 'rgb(241, 243, 249)',
                    },
                  }}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  label=""
                />
              </Box>

              {registrationStep === 'credentials' && (
                <>
                  {isSignUp && (
                    <Box sx={{ width: '100%' }}>
                      <Typography
                        variant="body1"
                        sx={{
                          color: 'rgba(0, 0, 0, 0.54)',
                          mb: 1,
                          fontSize: '16px',
                        }}
                      >
                        {t('Name')}
                      </Typography>
                      <TextField
                        type="text"
                        {...form.register('name')}
                        error={isSubmitted && !!form.formState.errors.name}
                        helperText={isSubmitted ? form.formState.errors.name?.message : ''}
                        required
                        fullWidth
                        disabled={isLoading}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            backgroundColor: 'rgb(241, 243, 249)',
                          },
                        }}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        label=""
                      />
                    </Box>
                  )}

                  <Box sx={{ width: '100%' }}>
                    <Typography
                      variant="body1"
                      sx={{
                        color: 'rgba(0, 0, 0, 0.54)',
                        mb: 1,
                        fontSize: '16px',
                      }}
                    >
                      {t('Password')}
                    </Typography>
                    <TextField
                      type="password"
                      {...form.register('password')}
                      error={registrationStep === 'credentials' && isSubmitted && !!form.formState.errors.password}
                      helperText={registrationStep === 'credentials' && isSubmitted && form.formState.isSubmitted ? form.formState.errors.password?.message : ''}
                      required
                      fullWidth
                      disabled={isLoading}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          backgroundColor: 'rgb(241, 243, 249)',
                        },
                      }}
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      label=""
                    />
                  </Box>
                </>
              )}

              <Button
                type="submit"
                variant="contained"
                fullWidth
                disabled={isLoading}
                sx={{ mt: 2 }}
              >
                {isLoading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : registrationStep === 'email' ? (
                  t('Next')
                ) : isSignUp ? (
                  t('Sign Up')
                ) : (
                  t('Login')
                )}
              </Button>

              {registrationStep !== 'email' && (
                <Button
                  onClick={handleBack}
                  variant="text"
                  disabled={isLoading}
                  sx={{ mt: 1 }}
                >
                  {t('Back')}
                </Button>
              )}

              {registrationStep === 'email' && (
                <Button
                  onClick={() => {
                    setIsSignUp(!isSignUp);
                    setWaitlistMessage(null);
                    form.clearErrors();
                  }}
                  variant="text"
                  disabled={isLoading}
                  sx={{ mt: 1 }}
                >
                  {isSignUp
                    ? t('Already have an account? Login')
                    : t("Don't have an account? Sign Up")}
                </Button>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default CustomLoginPage;
