import React from 'react';
import useExperiences from '../../queryHelpers/useExperiences';
import { FormParams } from '../../types';
import SingleSelectDrawer from '../SingleSelectDrawer';
import { PickerOption } from '../PickerDrawer';
import { useTranslation } from 'react-i18next';

type Props = {
  formParams: FormParams;
  onSelect: (experienceId: string) => Promise<{ error?: string }>;
};

const ExperienceSelectDrawer: React.FC<Props> = (props) => {
  const experiencesQuery = useExperiences();
  const { t } = useTranslation();

  const handleSelect = async (value: string) => {
    // Handle the selection of a service
    // You can add your logic here
    props.onSelect(value);
    return { error: undefined };
  };

  return (
    <SingleSelectDrawer
      formParams={props.formParams}
      title={t('New appointment for which service?')}
      options={
        experiencesQuery.data?.map((x) => ({
          text: x.summary.title!,
          value: x.id!,
        })) as PickerOption<string>[]
      }
      onSelect={handleSelect}
      confirmText="Confirm"
    />
  );
};

export default ExperienceSelectDrawer;
