import { BottomNavigation, BottomNavigationAction, useTheme, Badge } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import { WorkOutline, Work } from '@mui/icons-material';
import { routes } from '../routes';
import { useTranslation } from 'react-i18next';
import { useBookingCounts } from '../hooks/useBookingCounts';

export function AdminBottomNavigation() {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const { totalUnviewedCount } = useBookingCounts();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        navigate(routes.admin.bookings);
        break;
      case 1:
        navigate(routes.admin.experiences);
        break;
      case 2:
        navigate(routes.admin.settings);
        break;
    }
  };

  useEffect(() => {
    // Update the selected value based on the current path
    const path = location.pathname;
    if (path.includes(routes.admin.bookings)) setValue(0);
    else if (path.includes(routes.admin.experiences)) setValue(1);
    else if (path.includes(routes.admin.settings)) setValue(2);
  }, [location]);

  return (
    <BottomNavigation
      value={value}
      onChange={handleChange}
      showLabels
      sx={{
        backgroundColor: theme.palette.background.default,
        height: '56px',
        py: '16px',
        touchAction: 'none',
        fontSize: '0.75rem',
        '& .MuiBottomNavigationAction-root': {
          transition: 'none',
          '& .MuiTouchRipple-root': {
            display: 'none',
          },
          color: theme.palette.text.secondary,
          '&.Mui-selected': {
            color: theme.palette.primary.main,
          },
          '& .MuiBottomNavigationAction-label': {
            fontSize: '0.75rem',
            lineHeight: 1,
            WebkitTextSizeAdjust: 'none',
            textSizeAdjust: 'none',
          },
        },
        '& .Mui-selected': {
          transition: 'none',
        },
        borderTop: `1px solid ${theme.palette.divider}`,
      }}
    >
      <BottomNavigationAction
        label={t('Appointments')}
        icon={
          <Badge badgeContent={totalUnviewedCount ? `+${totalUnviewedCount}` : null} color="secondary">
            {value === 0 ? <CalendarMonthIcon /> : <CalendarMonthOutlinedIcon />}
          </Badge>
        }
      />
      <BottomNavigationAction
        label={t('Services')}
        icon={value === 1 ? <Work /> : <WorkOutline />}
      />
      <BottomNavigationAction
        label={t('Settings')}
        icon={value === 2 ? <SettingsIcon /> : <SettingsOutlinedIcon />}
      />
    </BottomNavigation>
  );
}
