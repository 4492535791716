import { Box, Divider, ListItemButton, Typography } from '@mui/material';
import AppDrawer from './AppDrawer';
import { useState } from 'react';
import { FormParams } from '../types';
import { PickerOption } from './PickerDrawer';
import { AppIcon } from './AppIcon';

interface SingleSelectDrawerProps<T> {
  formParams: FormParams; // Adjust type as necessary for formParams
  title: string;
  description?: string;
  options: PickerOption<T>[];
  onSelect: (value: T) => Promise<{ error?: string }>;
  confirmText: string;
}

const SingleSelectDrawer = <T extends any>(
  props: SingleSelectDrawerProps<T>,
) => {
  const [error, setError] = useState<string | null>(null);

  const handleItemClick = async (value: T) => {
    setError(null);

    try {
      const result = await props.onSelect(value);
      if (result.error) {
        setError(result.error);
      } else {
        props.formParams.onClose();
      }
    } catch (err: unknown) {
      setError('An unknown error occurred');
    }
  };

  return (
    <AppDrawer
      formParams={props.formParams}
      title={props.title}
      description={props.description}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          mb: '16px',
        }}
      >
        <Box sx={{ my: '32px' }}>
          <Box
            // ref={scrollRef}
            sx={{
              maxHeight: 300,
              overflowY: 'auto',
              '&::-webkit-scrollbar': { display: 'none' },
              scrollSnapType: 'y mandatory',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              backgroundColor: (theme) => theme.palette.background.paper,
              borderRadius: (theme) => `${theme.shape.borderRadius}px`,
            }}
          >
            {props.options.map((item, index) => (
              <ListItemButton
                key={item.text}
                sx={{ width: '100%', px: '16px', py: 0 }}
              >
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center',
                  }}
                  onClick={() => handleItemClick(item.value)}
                >
                  <Typography
                    sx={{
                      flexGrow: 1,
                      py: '16px',
                      px: '5px',
                      cursor: 'pointer',
                      scrollSnapAlign: 'center',
                      fontSize: '16px',
                      textAlign: 'left',
                    }}
                  >
                    {item.text}
                  </Typography>
                  <Box sx={{ width: '23px', height: '16px' }}>
                    <AppIcon src="/images/arrow_right.svg" height={'16px'} />
                  </Box>
                </Box>
                {index !== props.options.length - 1 && <Divider flexItem />}
              </ListItemButton>
            ))}
          </Box>

          {error && (
            <Typography
              sx={{
                color: 'error.main',
                mb: 2,
                textAlign: 'center',
              }}
            >
              {error}
            </Typography>
          )}
        </Box>
      </Box>
    </AppDrawer>
  );
};

export default SingleSelectDrawer;
