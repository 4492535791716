import { useMemo } from 'react';
import useBookings from '../queryHelpers/useBookings';
import { useTimeZone } from '../contexts/TimeZoneContext';

export function useBookingCounts() {
  const { data: bookings } = useBookings();
  const { timezone } = useTimeZone();

  return useMemo(() => {
    const bookingCounts: Record<string, number> = {};
    const unviewedCounts: Record<string, number> = {};
    let totalUnviewedCount = 0;

    if (bookings) {
      bookings.forEach(booking => {
        const dateStr = booking.timeSlot.start.setZone(timezone).toISODate();

        // Only count non-cancelled bookings
        if (!booking.cancelledAt) {
          bookingCounts[dateStr!] = (bookingCounts[dateStr!] || 0) + 1;

          // Count unviewed bookings
          if (!booking.viewed) {
            unviewedCounts[dateStr!] = (unviewedCounts[dateStr!] || 0) + 1;
            totalUnviewedCount++;
          }
        }
      });
    }

    return {
      bookingCounts,
      unviewedCounts,
      totalUnviewedCount
    };
  }, [bookings, timezone]);
}
