import React from 'react';
import SummaryEditor from '../../../../components/experiences/SummaryEditor';
import { useEditForm } from '../../../../hooks/useEditForm';
import { SummaryFormInputs, SummaryFormSchema } from '../../../../components/experiences/SummaryEditor';
import useCreateExperience from '../../../../queryHelpers/useCreateExperience';
import { LoadingButton } from '@mui/lab';
import { createNewExperienceVM } from '../../../../viewModels';
import { useTranslation } from 'react-i18next';
import AppDrawer from '../../../../components/AppDrawer';
import { FormParams } from '../../../../types';

interface CreateExperienceDialogProps {
  formParams: FormParams;
}

export const CreateExperienceDrawer: React.FC<CreateExperienceDialogProps> = ({
  formParams,
}) => {
  const { t } = useTranslation();

  const {
    form: {
      register,
      handleSubmit,
      watch,
      formState: { errors },
    },
  } = useEditForm<SummaryFormInputs>({
    defaultValues: {
      title: '',
      description: '',
      duration: 60,
      link: '',
    },
    formSchema: SummaryFormSchema(false),
  });

  const createExperienceMutation = useCreateExperience();

  const handleSave = (data: SummaryFormInputs) => {
    const newExperience = createNewExperienceVM();
    createExperienceMutation.mutate(
      {
        ...newExperience,
        summary: data,
      },
      {
        onSuccess: () => {
          formParams.onClose();
        },
        onError: () => {},
      },
    );
  };

  return (
    <AppDrawer formParams={formParams}>
      <form onSubmit={handleSubmit(handleSave)}>
        <SummaryEditor
          autoLink={true}
          register={register}
          errors={errors}
          watch={watch}
          hideDuration={true}
        />
        <LoadingButton
          loading={createExperienceMutation.isPending}
          fullWidth
          variant="contained"
          sx={{ mt: '16px', mb: '16px' }}
          type="submit"
        >
          {t('Create service')}
        </LoadingButton>
      </form>
    </AppDrawer>
  );
};

export default CreateExperienceDrawer;
