import { Chip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

interface CancelledFilterProps {
  showCancelled: boolean;
  onToggle: (show: boolean) => void;
}

export default function CancelledFilter({ showCancelled, onToggle }: CancelledFilterProps) {
  const { t } = useTranslation();

  return (
    <Chip
      label={t('Cancelled.Filter')}
      onClick={() => onToggle(true)}
      onDelete={showCancelled ? () => onToggle(false) : undefined}
      deleteIcon={<CloseIcon />}
      color={showCancelled ? 'info' : 'default'}
      sx={{
        cursor: 'pointer'
      }}
    />
  );
}
