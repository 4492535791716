import {
  AppBar,
  Toolbar,
  Button,
  Tabs,
  Tab,
  Menu,
  MenuItem,
} from '@mui/material';
import { Link, Outlet } from 'react-router-dom';
import { routes } from '../../routes';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useLanguage } from '../../contexts/LanguageContex';

const LandingLayout: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { language } = useLanguage();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isContentVisible, setIsContentVisible] = useState(false);

  useEffect(() => {
    // Ensure the correct language is set on mount
    i18n.changeLanguage(language);

    // Add a short timeout before showing content
    const timer = setTimeout(() => {
      setIsContentVisible(true);
    }, 300); // 300ms delay, adjust as needed

    return () => clearTimeout(timer);
  }, [language, i18n]);

  const handleLanguageClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLanguageClose = (lang?: string) => {
    setAnchorEl(null);
    if (lang) {
      // Use window.location to force a full page reload
      window.location.href = `/${lang}`;

      // Optionally, also update the language in the context
      // changeLanguage(lang as AppLanguage);
    }
  };

  return (
    <>
      <AppBar position="fixed" variant="elevation" elevation={1}>
        <Toolbar
          variant="dense"
          sx={{
            backgroundColor: (theme) => theme.palette.background.paper,
            paddingTop: 'env(safe-area-inset-top)',
            minHeight: (theme) =>
              `calc(${theme.mixins.toolbar.minHeight}px + env(safe-area-inset-top))`,
          }}
        >
          <div className="flex-grow">
            <Tabs value={routes.landing} aria-label="basic tabs example">
              <Tab
                href={routes.landing}
                label={t('MainMenu')}
                value={routes.landing}
              />
            </Tabs>
          </div>
          <div className="flex flex-row items-center space-x-2">
            <Button
              onClick={handleLanguageClick}
              className="min-w-[80px] normal-case"
              endIcon={<KeyboardArrowDownIcon />}
              sx={{
                border: '1px solid #e0e0e0',
                borderRadius: '4px',
                padding: '4px 8px',
                color: 'inherit',
                backgroundColor: 'transparent',
              }}
            >
              {language.toUpperCase()}
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => handleLanguageClose()}
              PaperProps={{
                elevation: 0,
                sx: {
                  minWidth: '80px',
                  border: '1px solid #e0e0e0',
                  borderRadius: '4px',
                  mt: 1,
                },
              }}
            >
              <MenuItem onClick={() => handleLanguageClose('en')}>EN</MenuItem>
              <MenuItem onClick={() => handleLanguageClose('ru')}>RU</MenuItem>
            </Menu>
            <Button
              component={Link}
              variant="contained"
              to={routes.login}
              color="primary"
            >
              {t('Login')}
            </Button>
          </div>
        </Toolbar>
      </AppBar>
      {isContentVisible && (
        <main
          style={{
            flexGrow: 1,
            marginTop: '48px' /* Adjust based on your AppBar height */,
            paddingTop: 'env(safe-area-inset-top)',
            minHeight: 'calc(var(--vh, 1vh) * 100 - 48px)',
            paddingBottom: 'max(env(safe-area-inset-bottom), 16px)',
            boxSizing: 'border-box',
          }}
        >
          <Outlet />
        </main>
      )}
    </>
  );
};

export default LandingLayout;
