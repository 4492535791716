import React, { useEffect, useState } from 'react';
import { Box, FormHelperText, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TimeSlotVM } from '../../../viewModels';
import { timeToString } from '../../../helpers/timeHelpers';
import AppErrorButton from '../../AppErrorButton';
import TimeSlotEditorDrawer from './TimeSlotEditorDrawer';

interface TimeSlotSelectorProps {
  slots: TimeSlotVM[];
  helperText?: string;
  error?: boolean;
  onChange?: (slots: TimeSlotVM[]) => void;
}

const TimeSlotsEditor: React.FC<TimeSlotSelectorProps> = (props) => {
  const { t } = useTranslation();
  const [isTimeSlotEditorOpen, setIsTimeSlotEditorOpen] = useState(false);
  const [selectedSlot, setSelectedSlot] = React.useState<TimeSlotVM | null>();
  const [slots, setSlots] = React.useState<TimeSlotVM[]>(props.slots);

  useEffect(() => {
    setSlots(props.slots);
  }, [props.slots]);

  const handleClickSlot = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    slot: TimeSlotVM,
  ) => {
    setSelectedSlot(slot);
    setIsTimeSlotEditorOpen(true);
  };

  const handleClickAdd = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    setIsTimeSlotEditorOpen(true);
    const maxEnd = slots.reduce((max, slot) => Math.max(max, slot.end), 0);
    setSelectedSlot({
      start: maxEnd === 0 ? 720 : maxEnd,
      end: maxEnd === 0 ? 720 : maxEnd,
    });
  };

  const handleClose = () => {
    setIsTimeSlotEditorOpen(false);
  };

  const handleDelete = () => {
    const newSlotCollection = slots.filter((slot) => slot !== selectedSlot);
    setSlots(newSlotCollection);
    if (props.onChange) {
      props.onChange(newSlotCollection);
    }
    handleClose();
  };

  const handleDone = (slot: { start: number; end: number }) => {
    const existingSlot = slots.find((slot) => slot === selectedSlot);
    if (existingSlot) {
      existingSlot.start = slot.start;
      existingSlot.end = slot.end;
      if (props.onChange) {
        props.onChange(slots);
      }
    } else {
      const newSlotCollection = [...slots, slot];
      setSlots(newSlotCollection);
      if (props.onChange) {
        props.onChange(newSlotCollection);
      }
    }

    handleClose();
  };

  return (
    <Box>
      <Box>
        <Stack
          direction="column"
          spacing={'8px'}
          sx={{ my: '8px' }}
          textAlign={'center'}
          flexWrap={'wrap'}
          useFlexGap
        >
          {props.onChange && (
            <>
              {slots
                .sort((a, b) => a.start - b.start)
                .map((slot) => (
                  <AppErrorButton
                    key={slot.start}
                    onClick={(e) => handleClickSlot(e, slot)}
                    color="text.primary"
                    text={
                      timeToString(slot.start) + ' - ' + timeToString(slot.end)
                    }
                  />
                ))}

              <AppErrorButton
                onClick={handleClickAdd}
                text="+"
                color="text.primary"
              />
            </>
          )}
          {!props.onChange && (
            <>
              {slots
                .sort((a, b) => a.start - b.start)
                .map((slot) => (
                  <Typography key={slot.start}>
                    {timeToString(slot.start) + ' - ' + timeToString(slot.end)}
                  </Typography>
                ))}
              {slots.length === 0 && (
                <Typography>{t('No available slots')}</Typography>
              )}
            </>
          )}
        </Stack>
      </Box>
      {props.helperText && (
        <FormHelperText error={props.error}>{props.helperText}</FormHelperText>
      )}

      <TimeSlotEditorDrawer
        formParams={{
          open: isTimeSlotEditorOpen,
          onClose: () => setIsTimeSlotEditorOpen(false),
        }}
        selectedSlot={{
          start: selectedSlot?.start ?? 0,
          end: selectedSlot?.end ?? 0,
        }}
        onDelete={
          slots.find((slot) => slot === selectedSlot) ? handleDelete : null
        }
        onSave={handleDone}
      />
    </Box>
  );
};

export default TimeSlotsEditor;
