import { useMemo } from 'react';
import { FormParams } from '../../types';
import { useTranslation } from 'react-i18next';
import { PickerOption } from '../PickerDrawer';
import useUpdateUserSettings from '../../queryHelpers/useUpdateUserSettings';
import TransparentPickerDrawer from '../TransparentPickerDrawer';
import { AppLanguage } from 'shared-ts/entities';
import { useUserContext } from '../../contexts/UserContext';

interface Props {
  formParams: FormParams;
}

const LanguagePickerDrawer: React.FC<Props> = ({ formParams }) => {
  const { t } = useTranslation();
  const { user } = useUserContext();
  const updateUserMutation = useUpdateUserSettings();

  const options = useMemo(() => {
    return [
      { text: 'English', value: 'en' as AppLanguage },
      { text: 'Русский', value: 'ru' as AppLanguage },
    ] as PickerOption<AppLanguage>[];
  }, []);

  const handleSelect = async (language: AppLanguage) => {
    try {
      await updateUserMutation.mutateAsync({
        id: user!.id,
        language,
      });
      return {};
    } catch (error) {
      return {
        error: t('Failed to update language'),
      };
    }
  };

  return (
    <TransparentPickerDrawer
      formParams={formParams}
      description={t('Select language')}
      options={options}
      onSelect={handleSelect}
    />
  );
};

export default LanguagePickerDrawer;
