import { useQuery } from '@tanstack/react-query';
import { queryKeys } from './queryKeys';
import AppService from '../services/appService';
import { experienceDbToVM } from './mapping';
import { useUserContext } from '../contexts/UserContext';

export default function useExperiences() {
  const { user } = useUserContext();

  const experiencesQuery = useQuery({
    queryKey: [queryKeys.experiences],
    enabled: !!user,
    queryFn: async () => {
      const service = new AppService();
      const experiences = await service.getExperiencesByAccountId(
        user?.accountId!,
      );
      return experiences.map((x) => experienceDbToVM(x));
    }
  });

  return experiencesQuery;
}
