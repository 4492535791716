import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from 'react';
import { useUserContext } from './UserContext';
import i18n from '../i18n';
import { AppLanguage } from 'shared-ts/entities';
import { enUS, ru } from 'date-fns/locale';
import { useParams } from 'react-router-dom';

interface LanguageContextType {
  language: AppLanguage;
  changeLanguage: (language: AppLanguage) => void;
  dateLocale: Locale;
}

const LanguageContext = createContext<LanguageContextType | undefined>(
  undefined,
);

export const getDefaultLanguage = (): AppLanguage => {
  const browserLanguage = navigator.language || navigator.language; // Get browser language
  return browserLanguage.startsWith('ru') ? 'ru' : 'en'; // Default to 'ru' if Russian
};

export const LanguageProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { user } = useUserContext();
  const { lang } = useParams();

  const [language, setLanguage] = useState<AppLanguage>(() => {
    console.log('lang', lang);
    if (lang) {
      return lang as AppLanguage;
    }

    // Get language from local storage or default to browser language
    const storedLanguage = localStorage.getItem('language');
    if (storedLanguage) {
      return storedLanguage as AppLanguage;
    }

    return getDefaultLanguage();
  });

  useEffect(() => {
    // Set the initial language for i18n
    console.log('language', language);
    i18n.changeLanguage(language);
    // Save the selected language to local storage
    // localStorage.setItem('language', language);
  }, [language]);

  useEffect(() => {
    if (lang) {
      return;
    }
    if (!user) {
      return;
    }
    if (user?.language !== language) {
      setLanguage(user.language as AppLanguage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const changeLanguage = (language: AppLanguage) => {
    setLanguage(language);
    localStorage.setItem('language', language);
  };

  // useEffect(() => {
  //   if (user?.timezone && user.timezone !== timezone) {
  //     setTimezone(user.timezone);
  //   }
  // }, [timezone, user]);

  return (
    <LanguageContext.Provider
      value={{
        language,
        changeLanguage,
        dateLocale: language === 'ru' ? ru : enUS,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (context === undefined) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};
