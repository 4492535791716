import { FormParams } from '../../types';
import { useTranslation } from 'react-i18next';
import YesNoPickerDrawer from '../YesNoPickerDrawer';

interface Props {
  formParams: FormParams;
  onSelect: (value: boolean) => Promise<{ error?: string }>;
}

const OnlyConsecutiveReservationsPickerDrawer: React.FC<Props> = ({
  formParams,
  onSelect,
}) => {
  const { t } = useTranslation();

  return (
    <YesNoPickerDrawer
      formParams={formParams}
      text={t('Allow only consecutive reservations.Description')}
      onSelect={onSelect}
    />
  );
};

export default OnlyConsecutiveReservationsPickerDrawer;
