import { Box } from '@mui/material';
import { useState } from 'react';
import AppDrawer from '../AppDrawer';
import { FormParams } from '../../types';
import { DateTimeSlotVM, TimeSlotVM } from '../../viewModels';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import React from 'react';
import { useTimeZone } from '../../contexts/TimeZoneContext';
import {
  convertToDateTimeSlots,
  convertToTimeSlots,
} from '../../helpers/dateHelpers';
import { useEditForm } from '../../hooks/useEditForm';
import useExperience from '../../queryHelpers/useExperience';
import useUpdateExperience from '../../queryHelpers/useUpdateExperience';
import { DayCalendarSelector } from './scheduleEdit/DayCalendarSelector';
import TimeSlotsEditor from './scheduleEdit/TimeSlotsEditor';
import * as yup from 'yup';
import { LoadingButton } from '@mui/lab';

interface ScheduleEditDrawerProps {
  formParams: FormParams;
  experienceId: string;
}

export const WeekDayAvailabilityFormSchema = yup.object({}).required();

export type WeekDayAvailabilityFormInputs2 = {
  availabilities: DateTimeSlotVM[];
};

const ScheduleEditDrawer: React.FC<ScheduleEditDrawerProps> = (props) => {
  const experienceQuery = useExperience({ id: props.experienceId });
  const { timezone } = useTimeZone();
  const [selectedDate, setSelectedDate] = useState<DateTime | undefined>(
    DateTime.now().setZone(timezone),
  );
  const { form } = useEditForm<WeekDayAvailabilityFormInputs2>({
    defaultValues: {
      availabilities:
        experienceQuery.data?.availabilities.map((x) => ({
          start: x.start.setZone(timezone),
          end: x.end.setZone(timezone),
        })) ?? [],
    },
    formSchema: WeekDayAvailabilityFormSchema,
  });

  const availabilities = form?.watch('availabilities');

  const [dayAvailabilities, setDayAvailabilities] = useState<DateTimeSlotVM[]>(
    () =>
      availabilities?.filter((d) => d.start.hasSame(selectedDate!, 'day')) ??
      [],
  );

  const { t } = useTranslation();

  const updateUserSettingsMutation = useUpdateExperience(props.experienceId);

  const onSubmit = async (data: WeekDayAvailabilityFormInputs2) => {
    await updateUserSettingsMutation.mutateAsync({
      availabilities: data.availabilities.map((slot) => ({
        start: slot.start.setZone(timezone, { keepLocalTime: true }),
        end: slot.end.setZone(timezone, { keepLocalTime: true }),
      })),
    });
    props.formParams.onClose();
  };

  const handleDateChange = (date: DateTime | undefined) => {
    var dayAvailabilities =
      availabilities?.filter((d) => d.start.hasSame(date!, 'day')) ?? [];
    setDayAvailabilities(dayAvailabilities);
    setSelectedDate(date);
  };

  const handleSlotsChange = (slots: TimeSlotVM[]) => {
    const newDateTimeSlots: DateTimeSlotVM[] = convertToDateTimeSlots(
      selectedDate!,
      slots,
    );

    const availabilitiesWithoutSelectedDate =
      availabilities?.filter((d) => !d.start.hasSame(selectedDate!, 'day')) ??
      [];

    form?.setValue('availabilities', [
      ...availabilitiesWithoutSelectedDate,
      ...newDateTimeSlots,
    ]);
    setDayAvailabilities(newDateTimeSlots);
  };

  return (
    <AppDrawer
      title={t('Schedule')}
      formParams={props.formParams}
      notScrollable
    >
      <form
        onSubmit={form?.handleSubmit(onSubmit)}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            paddingBottom: '16px',
          }}
        >
          <DayCalendarSelector
            onChange={handleDateChange}
            value={selectedDate}
            availabilities={availabilities}
          />
          <Box
            sx={{
              mb: '16px',
              overflowY: 'auto',
              height: '180px',
              maxHeight: '140px',
            }}
          >
            <TimeSlotsEditor
              slots={convertToTimeSlots(dayAvailabilities)}
              onChange={handleSlotsChange}
            />
          </Box>

          <LoadingButton
            variant="contained"
            color="primary"
            type="submit"
            loading={updateUserSettingsMutation.isPending}
          >
            {t('Save')}
          </LoadingButton>
        </Box>
      </form>
    </AppDrawer>
  );
};

export default ScheduleEditDrawer;
