import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';

import { useTranslation } from 'react-i18next';
import SettingsInterface from '../../../components/settingsUser/SettingsInterface';
import UserProfile from '../../../components/settingsUser/UserProfile';
import AppErrorButton from '../../../components/AppErrorButton';
import { useAdminOutletContext } from '../AdminLayout';
import LogoutConfirmationDrawer from '../../../components/settingsUser/LogoutConfirmationDrawer';

interface SettingsPageProps {}

const SettingsPage: React.FC<SettingsPageProps> = () => {
  const { t } = useTranslation();
  const outletContext = useAdminOutletContext();
  const [isLogoutConfirmationOpen, setIsLogoutConfirmationOpen] = useState(false);

  useEffect(() => {
    outletContext.setAppBars({
      TopAppBar: {
        title: t('Settings'),
        hide: true,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
        pb: '16px',
      }}
    >
      <UserProfile />
      <SettingsInterface />
      <AppErrorButton text={t('Logout')} onClick={() => setIsLogoutConfirmationOpen(true)} />

      <LogoutConfirmationDrawer
        formParams={{
          open: isLogoutConfirmationOpen,
          onClose: () => setIsLogoutConfirmationOpen(false),
        }}
      />
    </Box>
  );
};

export default SettingsPage;
