import { useMemo } from 'react';
import { getTimeSlotsForSelection } from '../helpers/timeslotsHelpers';
import { BookingVM, DateTimeSlotVM, ExperienceVM } from '../viewModels';
import useAvailableTimeSlots from '../queryHelpers/useAvailableTimeSlots';
import { DateTime } from 'luxon';

const useTimeSlotsForSelection = (
  selectedDate: DateTime | null,
  experienceData?: ExperienceVM | null, // Adjust type as necessary
  booking?: BookingVM | null,
  timezone?: string | null,
): DateTimeSlotVM[] => {
  const availableTimeSlotsQuery = useAvailableTimeSlots(
    experienceData?.id!,
    selectedDate ?? null,
    booking?.id,
  );

  return useMemo(() => {
    if (!availableTimeSlotsQuery.data || !experienceData) {
      return [];
    }

    const experienceDuration = experienceData.summary.duration!;

    const tmpTimeSlotsForSelection = getTimeSlotsForSelection(
      availableTimeSlotsQuery.data,
      experienceDuration,
    );

    if (booking) {
      const bookingDateTimeSlot = tmpTimeSlotsForSelection.find(
        (x) =>
          x.start.hasSame(booking.timeSlot.start, 'minute') &&
          x.end.hasSame(booking.timeSlot.end, 'minute'),
      );

      if (!bookingDateTimeSlot) {
        tmpTimeSlotsForSelection.push(booking.timeSlot);
      }
    }

    return tmpTimeSlotsForSelection.sort((a, b) => a.start.diff(b.start).toMillis()).map((x) => ({
      start: x.start.setZone(timezone ?? DateTime.local().zone),
      end: x.end.setZone(timezone ?? DateTime.local().zone),
    }));
  }, [availableTimeSlotsQuery, booking, experienceData, timezone]);
};

export default useTimeSlotsForSelection;
