import { Box, FormHelperText, Typography } from '@mui/material';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

interface FieldProps extends PropsWithChildren {
  label: string;
  errorMessage?: string;
  helperText?: string;
}

export const Field: React.FC<FieldProps> = (props) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
      }}
    >
      <Typography variant="inputLabel">{props.label}</Typography>
      {props.children}
      {!!props.errorMessage && (
        <FormHelperText error>
          {t(props.errorMessage)}
        </FormHelperText>
      )}
      {!!props.helperText && (
        <FormHelperText>{t(props.helperText)}</FormHelperText>
      )}
    </Box>
  );
};
