import { useState } from 'react';
import AppDrawer from '../../AppDrawer';
import { t } from 'i18next';
import { Box, Button } from '@mui/material';
import { TimeRangeSelector } from './TimeRangeSelector';
import { FormParams } from '../../../types';
import AppErrorButton from '../../AppErrorButton';

interface TimeSlotEditorDrawerProps {
  formParams: FormParams;
  selectedSlot?: { start: number; end: number };
  onDelete: (() => void) | null;
  onSave: (slot: { start: number; end: number }) => void;
}

const TimeSlotEditorDrawer = (props: TimeSlotEditorDrawerProps) => {
  const [selectedRange, setSelectedRange] = useState<
    | {
        start: number;
        end: number;
      }
    | undefined
  >(props.selectedSlot);

  const handleSave = () => {
    if (selectedRange) {
      props.onSave(selectedRange);
    }
  };

  return (
    <AppDrawer formParams={props.formParams} title={t('Working time range')}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          mb: '16px',
        }}
      >
        <TimeRangeSelector
          startTime={props.selectedSlot?.start ?? 0}
          endTime={props.selectedSlot?.end ?? 0}
          onTimeRangeChange={(start, end) => setSelectedRange({ start, end })}
        />

        <Button
          type="submit"
          variant="contained"
          onClick={handleSave}
          fullWidth
        >
          {t('Select')}
        </Button>

        {props.onDelete && (
          <AppErrorButton
            onClick={props.onDelete!}
            fullWidth
            text={t('Delete')}
          />
        )}
      </Box>
    </AppDrawer>
  );
};

export default TimeSlotEditorDrawer;
