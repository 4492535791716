import { Box, Typography, Button, Divider } from '@mui/material';
import useBookings from '../../../queryHelpers/useBookings';
import { groupBy } from 'lodash';
import { Fragment, useEffect, useState } from 'react';
import { useAdminOutletContext } from '../AdminLayout';
import useExperiences from '../../../queryHelpers/useExperiences';
import { DateTime } from 'luxon';
import { useTimeZone } from '../../../contexts/TimeZoneContext';
import { useTranslation } from 'react-i18next';
import AppCard from '../../../components/AppCard';
import ExperienceSelectDrawer from '../../../components/bookings/ExperienceSelectDrawer';
import BookingDetailDrawer from '../../../components/bookings/BookingDetailDrawer';
import { routes } from '../../../routes';
import { useNavigate } from 'react-router-dom';
import CreateBookingsDrawer from '../../../components/bookings/CreateBookingsDrawer';
import { BookingVM } from '../../../viewModels';
import WeekCalendarToolbar from '../../../components/bookings/WeekCalendarToolbar';
import { useMarkBookingsViewed } from '../../../hooks/useMarkBookingsViewed';
import { useBookingCounts } from '../../../hooks/useBookingCounts';
import CancelledFilter from '../../../components/bookings/CancelledFilter';
import ServiceFilter from '../../../components/bookings/ServiceFilter';

export default function BookingsPage() {
  const { timezone } = useTimeZone();
  const { data } = useBookings();
  const { t } = useTranslation();
  const experiencesQuery = useExperiences();
  const [selectedBookingId, setSelectedBookingId] = useState<string | null>(null);
  const [showCancelled, setShowCancelled] = useState(false);
  const [filterExperienceId, setFilterExperienceId] = useState<string>('all');
  const groupedBookings = groupBy(data, (d) =>
    d.timeSlot.start.setZone(timezone).toISODate(),
  );
  const [openBookingDetailDrawer, setOpenBookingDetailDrawer] = useState(false);
  const [selectedDate, setSelectedDate] = useState<DateTime>(DateTime.now());
  const [openServiceSelectDrawer, setOpenServiceSelectDrawer] = useState(false);
  const [openCreateBooking, setOpenCreateBooking] = useState(false);
  const [selectedExperienceId, setSelectedExperienceId] = useState<string | null>(null);
  const outletContext = useAdminOutletContext();
  const navigate = useNavigate();
  const { markAsViewed } = useMarkBookingsViewed();
  const { bookingCounts, unviewedCounts } = useBookingCounts();

  useEffect(() => {
    outletContext.setAppBars({
      TopAppBar: {
        title: t('Appointments'),
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  const handleExperienceSelect = async (experienceId: string) => {
    setSelectedExperienceId(experienceId);
    setOpenCreateBooking(true);
    return { error: undefined };
  };

  const handleBookingClick = (bookingId: string) => {
    setSelectedBookingId(bookingId);
    setOpenBookingDetailDrawer(true);
    markAsViewed(bookingId);
  };

  const handleCreateBooking = (booking: Omit<BookingVM, 'createdIn'>) => {
    setSelectedDate(booking.timeSlot.start);
  };

  const handleServiceSelect = (experienceId: string) => {
    setFilterExperienceId(experienceId);
  };


  if (data === undefined) return null;

  // Filter bookings for selected date
  const selectedDateStr = selectedDate.toISODate();
  const bookingsForSelectedDate = (groupedBookings[selectedDateStr!] || [])
    .filter(booking => {
      if (!showCancelled && booking.cancelledAt) return false;
      if (filterExperienceId !== 'all' && booking.experienceId !== filterExperienceId) return false;
      return true;
    })
    .sort((a, b) => a.timeSlot.start.toMillis() - b.timeSlot.start.toMillis());

  if (experiencesQuery.data?.length === 0) {
    return (
      <Box
        sx={{
          display: 'flex',
          gap: '16px',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <Typography>
          {t(
            'You don not have any services yet. Go to the services page to create one.',
          )}
        </Typography>
        <Button
          variant="contained"
          onClick={() => navigate(routes.admin.experiences)}
          fullWidth
        >
          {t('Go to services')}
        </Button>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '16px',
        flexDirection: 'column',
      }}
    >
      <WeekCalendarToolbar
        currentDate={selectedDate}
        onSelectDay={(date) => setSelectedDate(date)}
        bookings={bookingCounts}
        unviewedBookings={unviewedCounts}
      />

      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
        <CancelledFilter
          showCancelled={showCancelled}
          onToggle={setShowCancelled}
        />

        <ServiceFilter
          services={experiencesQuery.data || []}
          selectedServiceId={filterExperienceId}
          onServiceSelect={handleServiceSelect}
        />
      </Box>

      <Divider />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          overflowY: 'auto',
          maxHeight: 'calc(100vh - 200px)',
        }}
      >
        {bookingsForSelectedDate.length === 0 && (
          <>
            <Typography sx={{ textAlign: 'center' }}>
              {t('No appointments scheduled for this date')}
            </Typography>
            <Divider />
          </>
        )}
        {bookingsForSelectedDate.map((booking, index) => (
          <Fragment key={index}>
            <AppCard
              onClick={() => handleBookingClick(booking.id!)}
              badge={booking.cancelledAt ? {
                text: t('Cancelled'),
                color: 'error'
              } : undefined}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  {!booking.viewed && (
                    <Box
                      sx={{
                        width: 8,
                        height: 8,
                        borderRadius: '50%',
                        backgroundColor: 'secondary.main',
                      }}
                    />
                  )}
                  <Typography
                    sx={{
                      fontSize: '12px',
                      fontWeight: 800,
                      lineHeight: '14.06px',
                    }}
                  >
                    {`${booking.timeSlot.start
                      .setZone(timezone)
                      .toFormat('HH:mm')} - ${booking.timeSlot.end
                      .setZone(timezone)
                      .toFormat('HH:mm')}, ${experiencesQuery.data?.find(
                      (x) => x.id === booking.experienceId,
                    )?.summary.title}`}
                  </Typography>
                </Box>

                <Typography
                  sx={{
                    fontSize: '12px',
                    fontWeight: 400,
                    lineHeight: '14.06px',
                  }}
                >{`${booking.name}, ${booking.phone}`}</Typography>

                {booking.wishes && (
                  <Typography
                    component="div"
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.background.boxInPaper,
                      p: '4px',
                      borderRadius: (theme) => `${theme.shape.borderRadius}px`,
                      fontSize: '12px',
                      fontWeight: 400,
                      lineHeight: '14.06px',
                    }}
                  >
                    {booking.wishes}
                  </Typography>
                )}
              </Box>
            </AppCard>
            <Divider />
          </Fragment>
        ))}
      </Box>

      <Button
        variant="contained"
        onClick={() => setOpenServiceSelectDrawer(true)}
      >
        {t('Create Appointment')}
      </Button>

      <ExperienceSelectDrawer
        formParams={{
          open: openServiceSelectDrawer,
          onClose: () => setOpenServiceSelectDrawer(false),
        }}
        onSelect={handleExperienceSelect}
      />

      <CreateBookingsDrawer
        formParams={{
          open: openCreateBooking,
          onClose: () => setOpenCreateBooking(false),
        }}
        experienceId={selectedExperienceId}
        onBookingCreated={handleCreateBooking}
      />

      <BookingDetailDrawer
        formParams={{
          open: openBookingDetailDrawer,
          onClose: () => setOpenBookingDetailDrawer(false),
        }}
        bookingId={selectedBookingId}
      />
    </Box>
  );
}
