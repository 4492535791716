import { Box } from '@mui/material';

import { SwipeableDrawer } from '@mui/material';
import { PropsWithChildren } from 'react';
import { FormParams } from '../types';
import { useAppDrawerContext } from './AppDrawerContext';

type Props = PropsWithChildren & {
  title?: string;
  description?: string;
  formParams: FormParams;
  closeVariant?: 'icon' | 'text';
  height?: 'full';
};

const AppTransparentDrawer: React.FC<Props> = ({
  formParams,
  height,
  children,
}) => {
  const { paperProps } = useAppDrawerContext();
  return (
    <SwipeableDrawer
      anchor="bottom"
      open={formParams.open}
      onClose={formParams.onClose}
      onOpen={() => {}}
      PaperProps={{
        sx: {
          overflow: 'hidden',
          backgroundColor: 'transparent',
          boxShadow: 'none',
          ...paperProps?.sx,
        },
      }}

      sx={{
        width: '100%',
        position: 'relative',
        '& .MuiPaper-root': {
          borderTopLeftRadius: (theme) => theme.shape.borderRadius,
          borderTopRightRadius: (theme) => theme.shape.borderRadius,
          pb: 0,
          pt: 0,
          backgroundColor: 'transparent',
        },
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          height: height === 'full' ? '96vh' : 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            overflowY: 'auto',
            flexGrow: 1,
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }}
        >
          {children}
        </Box>
      </Box>
    </SwipeableDrawer>
  );
};

export default AppTransparentDrawer;
