import { DateTimeSlotVM } from '../viewModels';

/*
If duration is from 0 to 15 minutes, then timeslots should start from 0, 15, 30, 45 mintes.

If duration is from 15 to 30 minutes, then timeslots should start from 0, 30 minutes

Otherwise timeslots should start from 0 minutes
*/

export const getTimeSlotsForSelection = (
  timeSlots: DateTimeSlotVM[],
  duration: number,
): DateTimeSlotVM[] => {
  const splitSlots: DateTimeSlotVM[] = [];
  timeSlots.forEach((slot) => {
    let start = slot.start.startOf('minute');

    // Get valid start minutes based on duration
    const validStartMinutes = duration <= 15
      ? [0, 15, 30, 45]  // For durations up to 15 minutes
      : duration <= 30
        ? [0, 30]        // For durations up to 30 minutes
        : [0];           // For durations over 30 minutes

    // Find the next valid start minute
    const currentMinute = start.minute;
    const nextValidMinute = validStartMinutes.find(min => min > currentMinute) ?? validStartMinutes[0];

    // If we need to move to the next hour
    if (nextValidMinute <= currentMinute) {
      start = start.plus({ hours: 1 }).set({ minute: validStartMinutes[0] });
    } else {
      start = start.set({ minute: nextValidMinute });
    }

    // Check if the current minute is already a valid start time
    if (validStartMinutes.includes(currentMinute)) {
      start = slot.start;
    }

    while (start < slot.end) {
      const potentialEnd = start.plus({ minutes: duration });
      if (potentialEnd <= slot.end) {
        splitSlots.push({ start, end: potentialEnd });
      }

      // Move to next valid start minute
      const currentMin = start.minute;
      const nextMin = validStartMinutes.find(min => min > currentMin) ?? validStartMinutes[0];
      start = nextMin <= currentMin
        ? start.plus({ hours: 1 }).set({ minute: validStartMinutes[0] })
        : start.set({ minute: nextMin });
    }
  });
  return splitSlots;
};
