import {
  Box,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import { MouseEventHandler, ReactNode } from 'react';
import AppCard, { SecondaryAction } from './AppCard';
import { AppIcon } from './AppIcon';

type AppCardListItem = {
  title: string | ReactNode;
  value?: string | number;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

type AppCardListProps = {
  title?: string
  items: AppCardListItem[];
  secondaryAction?: SecondaryAction;
};

const AppCardList: React.FC<AppCardListProps> = (props) => {
  return (
    <AppCard title={props.title} secondaryAction={props.secondaryAction}>
      <List>
        {props.items.map((item, index) => (
          <ListItemButton
            key={`item-${index}`}
            sx={{
              py: '5px',
              px: (theme) =>
                (theme.components?.MuiCardContent?.styleOverrides?.root as any)
                  .padding,
              mx: (theme) =>
                `-${
                  (
                    theme.components?.MuiCardContent?.styleOverrides
                      ?.root as any
                  ).padding
                }`,
            }}
            onClick={item.onClick}
          >
            <ListItemText
              primaryTypographyProps={{
                variant: 'listItemLabel',
              }}
              primary={item.title}
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '6px',
                alignItems: 'center',
              }}
            >
              <Typography variant="listItemValue">{item.value}</Typography>
              <AppIcon src="/images/arrow_right.svg" />
            </Box>
          </ListItemButton>
        ))}
      </List>
    </AppCard>
  );
};

export default AppCardList;
