import React from 'react';
import { DateTime } from 'luxon';
import useBooking from '../../queryHelpers/useBooking';
import useExperience from '../../queryHelpers/useExperience';
import AppCardButton from '../AppCardButton';
import { useTranslation } from 'react-i18next';
import { useTimeZone } from '../../contexts/TimeZoneContext';

interface AddToCalendarCardProps {
  bookingId: string;
  disabled?: boolean;
}

export const AddToCalendarButton: React.FC<AddToCalendarCardProps> = ({
  bookingId,
  disabled,
}) => {
  const bookingQuery = useBooking(bookingId);
  const { t } = useTranslation();
  const { timezone } = useTimeZone();
  const experienceQuery = useExperience({
    id: bookingQuery.data?.experienceId as string,
    enabled: !!bookingQuery.data,
  });

  const handleAddToCalendar = () => {
    // Ensure we have the necessary data
    if (!bookingQuery.data?.timeSlot || !experienceQuery.data?.summary) return;

    const { start, end } = bookingQuery.data.timeSlot;

    // Create DateTime objects with the specified timezone
    const startDateTime = DateTime.fromObject({
      year: start.year,
      month: start.month,
      day: start.day,
      hour: start.hour,
      minute: start.minute,
    })
      .setZone(timezone)
      .toUTC();

    const endDateTime = DateTime.fromObject({
      year: end.year,
      month: end.month,
      day: end.day,
      hour: end.hour,
      minute: end.minute,
    })
      .setZone(timezone)
      .toUTC();

    const title = experienceQuery.data.summary.title;
    const description = experienceQuery.data.summary.description || '';

    // Generate iCal content with corrected date formatting
    const formatDateForIcal = (dt: DateTime): string => {
      return dt.toFormat('yyyyMMdd') + 'T' + dt.toFormat('HHmmss') + 'Z';
    };
    // Read https://icalendar.org/the-icalendar-standard-2.html
    const icalContent = `BEGIN:VCALENDAR
VERSION:2.0
PRODID:-//Your Company//Event Booking//EN
BEGIN:VEVENT
UID:'time2you-${bookingId}'
SUMMARY:${title}
DESCRIPTION:${description}
DTSTART:${formatDateForIcal(startDateTime)}
DTEND:${formatDateForIcal(endDateTime)}
DTSTAMP:${formatDateForIcal(DateTime.utc())}
URL:${window.location.href}
END:VEVENT
END:VCALENDAR`;

    // Create and download the .ics file
    const blob = new Blob([icalContent], {
      type: 'text/calendar;charset=utf-8',
    });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${title}.ics`;

    // Append to body, click, and remove
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
  };

  if (!bookingQuery.data?.timeSlot || !experienceQuery.data?.summary) {
    return null;
  }

  return (
    <AppCardButton
      iconPath={'/images/calendar.svg'}
      text={t('Add to calendar')}
      onClick={handleAddToCalendar}
      disabled={disabled}
    />
  );
};
