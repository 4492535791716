import { useMemo } from 'react';
import { FormParams } from '../../types';
import { useTranslation } from 'react-i18next';
import PickerDrawer, { PickerOption } from '../PickerDrawer';
import { minutesToTime } from '../../helpers/pluralHelpers';

interface Props {
  formParams: FormParams;
  onSelect: (minutes: number) => Promise<{ error?: string }>;
  value?: number;
}

const GapBetweenAppointmentsPickerDrawer: React.FC<Props> = ({
  formParams,
  onSelect,
  value,
}) => {
  const { t } = useTranslation();

  // Generate time durations based on the minute scale
  const options = useMemo(() => {
    const durations: PickerOption<number>[] = Array.from({ length: 25 }, (_, i) => i * 5).map((minutes) => {
      return {
        text: minutesToTime(minutes),
        value: minutes,
      };
    });
    return durations;
  }, []);

  return (
    <PickerDrawer
      formParams={formParams}
      title={t('Break between appointments')}
      options={options}
      value={value}
      onSelect={onSelect}
      confirmText={t('Select')}
    />
  );
};

export default GapBetweenAppointmentsPickerDrawer;
