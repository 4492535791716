import { useCallback, useRef, useEffect } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from '../queryHelpers/queryKeys';
import { MarkBookingsViewed } from '../firebaseFunctions';
import { throttle } from 'lodash';

export function useMarkBookingsViewed() {
  const queryClient = useQueryClient();
  const pendingBookingIds = useRef<Set<string>>(new Set());

  const mutation = useMutation({
    mutationFn: async (bookingIds: string[]) => {
      await MarkBookingsViewed({ bookingIds });
    },
    onSettled: () => {
      // Force a refetch instead of just invalidating
      queryClient.refetchQueries({
        queryKey: [queryKeys.bookings],
      });
    },
  });

  // Throttled function to send updates
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const sendUpdates = useCallback(
    throttle(async () => {
      if (pendingBookingIds.current.size > 0) {
        const ids = Array.from(pendingBookingIds.current);
        pendingBookingIds.current.clear();
        try {
          await mutation.mutateAsync(ids);
        } catch (error) {
          // If the update fails, put the IDs back in the pending set
          ids.forEach(id => pendingBookingIds.current.add(id));
        }
      }
    }, 5000),
    [mutation]
  );

  const markAsViewed = useCallback((bookingId: string) => {
    pendingBookingIds.current.add(bookingId);

    // Optimistically update the cache
    queryClient.setQueryData([queryKeys.bookings], (oldData: any) => {
      if (!oldData) return oldData;
      return oldData.map((booking: any) =>
        booking.id === bookingId ? { ...booking, viewed: true } : booking
      );
    });

    sendUpdates();
  }, [queryClient, sendUpdates]);

  // Cleanup function to ensure any pending updates are sent
  useEffect(() => {
    // Create a local reference to the current sendUpdates function
    const currentSendUpdates = sendUpdates;

    return () => {
      // Capture the current pending IDs at cleanup time
      // eslint-disable-next-line react-hooks/exhaustive-deps
      const pendingIds = Array.from(pendingBookingIds.current);
      if (pendingIds.length > 0) {
        // Use the captured sendUpdates function and immediately flush
        currentSendUpdates.flush();
      }
    };
  }, [sendUpdates]);

  return { markAsViewed };
}
