import { Outlet } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { Toaster } from 'react-hot-toast';
import theme from './theme';

// See https://reactcommunity.org/react-transition-group/with-react-router/
function LandingApp() {

  return (
    <ThemeProvider theme={theme}>
      <Toaster />
      <CssBaseline />
      <Outlet />
    </ThemeProvider>
  );
}

export default LandingApp;
