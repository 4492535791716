import { Button, ButtonProps } from '@mui/material';

interface LinkButtonProps extends ButtonProps {
  children: React.ReactNode;
  to: string;
}

const LinkButton = (props: LinkButtonProps) => {
  return (
    <Button
      variant="text"
      component="a"
      disableRipple
      sx={{
        textDecoration: 'none',
        p: 0,
        color: '#51B9FF',
        textTransform: 'none',
        minWidth: 'auto',
        minHeight: 'auto',
        '&:focus': {
          color: '#51B9FF',
        },
        '&:active': {
          color: '#007ACC', // Darker color when pressed
        },
      }}
      {...props}
    >
      {props.children}
    </Button>
  );
};

export default LinkButton;
