import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormParams } from '../../types';
import AppErrorButton from '../AppErrorButton';
import AppTransparentDrawer from '../AppTransparentDrawer';
import { Box, Divider, Typography, useTheme } from '@mui/material';

interface Props {
  formParams: FormParams;
  onConfirm: () => void;
  text: string | React.ReactNode;
}

const DeleteConfirmationDrawer: React.FC<Props> = ({
  formParams,
  onConfirm,
  text,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <AppTransparentDrawer formParams={formParams}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          mb: '16px',
        }}
      >
        <Box
          sx={{
            backgroundColor: (theme) =>
              (theme.components?.MuiCard?.styleOverrides?.root as any)
                .backgroundColor,
            borderRadius: (theme) => `${theme.shape.borderRadius}px`,
          }}
        >
          <Typography
            variant="body1"
            sx={{
              px: '16px',
              py: '16px',
              textAlign: 'center',
            }}
          >
            {text}
          </Typography>
          <Divider />
          <AppErrorButton
            text={t('Delete service')}
            onClick={onConfirm}
            fullWidth
            disableTopRadius
          />
        </Box>
        <AppErrorButton
          text={t('Cancel')}
          onClick={() => formParams.onClose()}
          color={theme.palette.action.active}
        />
      </Box>
    </AppTransparentDrawer>
  );
};

export default DeleteConfirmationDrawer;
