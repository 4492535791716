import { Box, SxProps, Theme } from '@mui/material';
import { useEffect, useState } from 'react';

interface AppIconProps {
  src: string;
  size?: string | number;
  width?: string | number;
  height?: string | number;
  sx?: SxProps<Theme>;
}

export const AppIcon = ({ src, size, width, height, sx }: AppIconProps) => {
  const [imageDimensions, setImageDimensions] = useState<{ width: number; height: number } | null>(null);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setImageDimensions({
        width: img.width,
        height: img.height,
      });
    };
    img.src = src;
  }, [src]);

  return (
    <Box
      sx={{
        ...(size ? { width: size, height: size } :
            {
              width: width ?? (imageDimensions?.width ?? undefined),
              height: height ?? (imageDimensions?.height ?? undefined)
            }),
        bgcolor: (theme) => theme.palette.text.primary,
        maskImage: `url(${src})`,
        maskSize: 'contain',
        maskRepeat: 'no-repeat',
        maskPosition: 'center',
        WebkitMaskImage: `url(${src})`,
        WebkitMaskSize: 'contain',
        WebkitMaskRepeat: 'no-repeat',
        WebkitMaskPosition: 'center',
        ...sx,
      }}
    />
  );
};
