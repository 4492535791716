import { useTranslation } from 'react-i18next';
import { FormParams } from '../types';
import AppTransparentDrawer from './AppTransparentDrawer';
import { Box, Divider, Typography, useTheme } from '@mui/material';
import toast from 'react-hot-toast';
import { createToastStyle } from '../shared/styles';
import { PickerOption } from './PickerDrawer';
import { useState } from 'react';
import React from 'react';
import AppErrorButton from './AppErrorButton';

export interface TransparentPickerOption<T> extends PickerOption<T> {
  text: string;
  value: T;
  isError?: boolean;
}

interface Props<T> {
  formParams: FormParams;
  description?: string;
  options: TransparentPickerOption<T>[];
  onSelect: (totalMinutes: T) => Promise<{ error?: string }>;
}

const TransparentPickerDrawer = <T extends any>(props: Props<T>) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSelect = async (index: number) => {
    setSelectedIndex(index);
    setIsLoading(true);

    try {
      const result = await props.onSelect(props.options[index].value);
      if (result.error) {
        toast.error(result.error, createToastStyle(theme));
      } else {
        props.formParams.onClose();
      }
    } catch (err: unknown) {
      const errorMessage = t('An unknown error occurred');
      toast.error(errorMessage, createToastStyle(theme));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AppTransparentDrawer formParams={props.formParams}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          mb: 'max(env(safe-area-inset-bottom), 16px)',
        }}
      >
        <Box
          sx={{
            backgroundColor: (theme) =>
              (theme.components?.MuiCard?.styleOverrides?.root as any)
                .backgroundColor,
            borderRadius: (theme) => `${theme.shape.borderRadius}px`,
          }}
        >
          {props.description && (
            <>
              <Typography
                variant="body1"
                sx={{
                  px: '16px',
                  py: '16px',
                  textAlign: 'center',
                }}
              >
                {props.description}
              </Typography>
              <Divider />
            </>
          )}
          {props.options.map((option, index) => (
            <React.Fragment key={index}>
              {index > 0 && <Divider />}
              <AppErrorButton
                text={option.text}
                onClick={() => handleSelect(index)}
                isLoading={isLoading && selectedIndex === index}
                disabled={isLoading}
                fullWidth
                disableTopRadius={index > 0}
                disableBottomRadius={index < props.options.length - 1}
                color={option.isError ? theme.palette.error.main : theme.palette.action.active}
              />
            </React.Fragment>
          ))}
        </Box>
        <AppErrorButton
          text={t('Cancel')}
          onClick={() => props.formParams.onClose()}
          color={theme.palette.action.active}
        />
      </Box>
    </AppTransparentDrawer>
  );
};

export default TransparentPickerDrawer;
