import React, { useState } from 'react';
import { Box, Typography, Checkbox, Link } from '@mui/material';
import AppDrawer from './AppDrawer';
import { FormParams } from '../types';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';

interface ConfirmationDrawerProps {
  formParams: FormParams;
  onConfirm?: () => Promise<{ error: string | null }>;
}

const ConfirmationDrawer: React.FC<ConfirmationDrawerProps> = ({
  formParams,
  onConfirm,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isChecked, setIsChecked] = useState(false);

  const handleConfirm = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const result = await onConfirm?.();
      if (result?.error) {
        setError(result.error);
      } else {
        formParams.onClose();
      }
    } catch (err: unknown) {
      setError(
        t(
          'We could not confirm your booking. Please try again later or pick another time slot',
        ),
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AppDrawer
      formParams={formParams}
      title={t('Confirm record')}
      titlePosition="center"
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          p: 2,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1 }}>
          <Checkbox
            sx={{ mt: -0.5 }}
            checked={isChecked}
            onChange={(e) => setIsChecked(e.target.checked)}
          />
          <Typography variant="body1">
            {t(
              'I agree to the processing of personal data and confirm that I have read and agree to',
            )}{' '}
            <Link href="/privacy" color="primary" underline="hover">
              {t('Privacy Policy')}
            </Link>{' '}
            {t('and')}{' '}
            <Link href="/terms" color="primary" underline="hover">
              {t('Terms of Service')}
            </Link>
          </Typography>
        </Box>

        {error && (
          <Typography
            sx={{
              color: 'error.main',
              textAlign: 'center',
            }}
          >
            {error}
          </Typography>
        )}

        <LoadingButton
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleConfirm}
          loading={isLoading}
          disabled={!isChecked}
          sx={{ mt: 2 }}
        >
          {t('Confirm')}
        </LoadingButton>
      </Box>
    </AppDrawer>
  );
};

export default ConfirmationDrawer;
