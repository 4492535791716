import React from 'react';
import tzData from 'tzdata';
import spacetime from 'spacetime';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { FormParams } from '../../types';
import PickerDrawer, { PickerOption } from '../PickerDrawer';
import useUpdateUserSettings from '../../queryHelpers/useUpdateUserSettings';
import { useUserContext } from '../../contexts/UserContext';

export const getTimezoneDisplayText = (
  timezone: string,
): { offset: string; name: string; now: Date; offsetHours: number } | null => {
  let spacetime1 = null;
  try {
    spacetime1 = spacetime.now(timezone);
  } catch (e) {
    return null;
  }

  const offset = spacetime1.timezone().current.offset;
  const hours = Math.floor(Math.abs(offset)).toString().padStart(2, '0'); // Get absolute hours
  const minutes = ((Math.abs(offset) % 1) * 60).toString().padStart(2, '0'); // Get minutes
  const utcOffset =
    offset >= 0 ? `UTC+${hours}:${minutes}` : `UTC-${hours}:${minutes}`; // Format offset

  const now = new Date(
    spacetime1.year(),
    spacetime1.month(),
    spacetime1.date(),
    spacetime1.hour(),
    spacetime1.minute(),
  );
  return { offset: utcOffset, offsetHours: offset, name: timezone, now: now };
};

interface Props {
  formParams: FormParams;
  value: string | null;
}

const TimezonePickerDrawer: React.FC<Props> = ({ formParams, value }) => {
  const { t } = useTranslation();
  const { user } = useUserContext();
  const updateUserMutation = useUpdateUserSettings();

  const options = React.useMemo(() => {
    const timezones = Object.keys(tzData.zones);
    return timezones
      .map((timezone) => {
        const label = getTimezoneDisplayText(timezone);
        if (!label) return null;
        return {
          text: `(${label.offset}) ${t(timezone)}`,
          value: timezone,
          secondary: `Now: ${format(label.now, 'HH:mm')}`,
          sortValue: label.offsetHours,
        } as PickerOption<string> & { sortValue: number };
      })
      .filter(
        (tz): tz is PickerOption<string> & { sortValue: number } => tz !== null,
      )
      .sort((a, b) => a.sortValue - b.sortValue);
  }, [t]);

  const handleSelect = async (timezone: string) => {
    try {
      await updateUserMutation.mutateAsync({
        id: user!.id,
        timezone,
      });
      return {};
    } catch (error) {
      return {
        error: t('Failed to update timezone'),
      };
    }
  };

  return (
    <PickerDrawer
      formParams={formParams}
      title={t('Select timezone')}
      options={options}
      value={value ?? undefined}
      onSelect={handleSelect}
      confirmText={t('Select')}
    />
  );
};

export default TimezonePickerDrawer;
