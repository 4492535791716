import {
  Chip,
  Popover,
  List,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ExperienceVM } from '../../viewModels';

interface ServiceFilterProps {
  services: ExperienceVM[];
  selectedServiceId: string;
  onServiceSelect: (serviceId: string) => void;
}

export default function ServiceFilter({
  services,
  selectedServiceId,
  onServiceSelect,
}: ServiceFilterProps) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (serviceId: string) => {
    onServiceSelect(serviceId);
    handleClose();
  };

  const selectedService = services.find(
    (service) => service.id === selectedServiceId,
  );

  return (
    <>
      <Chip
        label={
          selectedService ? selectedService.summary.title : t('All experiences')
        }
        onClick={handleClick}
        onDelete={
          selectedServiceId !== 'all' ? () => handleSelect('all') : undefined
        }
        deleteIcon={<CloseIcon />}
        color={selectedServiceId !== 'all' ? 'info' : 'default'}
        sx={{
          cursor: 'pointer',
        }}
      />

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{
          marginTop: '8px',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <List sx={{ minWidth: 200, py: 0 }}>
          <ListItemButton onClick={() => handleSelect('all')}>
            <ListItemText primary={t('All experiences')} />
          </ListItemButton>
          {services.map((service) => (
            <ListItemButton
              key={service.id}
              onClick={() => handleSelect(service.id!)}
            >
              <ListItemText primary={service.summary.title} />
            </ListItemButton>
          ))}
        </List>
      </Popover>
    </>
  );
}
