import { Box, List, ListItem, Typography } from '@mui/material';
import { DateTimeSlotVM } from '../../viewModels';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { useTimeZone } from '../../contexts/TimeZoneContext';
import { useTranslation } from 'react-i18next';

interface ScheduleListProps {
  slots: DateTimeSlotVM[];
}

const DAY_WIDTH = 40;

const ScheduleList = ({ slots }: ScheduleListProps) => {
  const { timezone } = useTimeZone();
  const { i18n } = useTranslation();

  const groupedSlots = useMemo(() => {
    const containerWidth = window.innerWidth; // Get the width of the window
    const dayWidth = DAY_WIDTH; // Each day takes 40px
    const daysToShow = Math.floor(containerWidth / dayWidth); // Calculate how many days can fit

    // Get today's date in the user's timezone
    const today = DateTime.now().setZone(timezone).startOf('day');
    const allDays = Array.from({ length: daysToShow }, (_, i) =>
      today.plus({ days: i }),
    );

    // Initialize groups with empty arrays for each day
    const groups = new Map<string, DateTimeSlotVM[]>(
      allDays.map((date) => [date.toISODate()!, []]),
    );

    slots.sort((a, b) => a.start.diff(b.start).toMillis()).forEach((slot) => {
      const dateKey = slot.start.setZone(timezone).toISODate();
      if (!dateKey) return;

      if (groups.has(dateKey)) {
        const existing = groups.get(dateKey) || [];
        groups.set(dateKey, [...existing, slot]);
      }
    });

    return groups;
  }, [slots, timezone]);

  return (
    <Box
      sx={{
        gap: '8px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <List
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 1,
          overflowX: 'auto',
          '&::-webkit-scrollbar': { display: 'none' },
        }}
      >
        {Array.from(groupedSlots.entries()).map(([date, daySlots]) => {
          const dateObj = DateTime.fromISO(date);

          return (
            <ListItem
              key={date}
              sx={{
                flexDirection: 'column',
                gap: '4px',
              }}
              className="scheduleItem"
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                className="scheduleItem__header"
              >
                <Typography
                  variant="paperBox"
                  color={dateObj.isWeekend ? 'error' : 'text.primary'}
                >
                  {dateObj.day}
                </Typography>
                <Typography
                  variant="paperBox"
                  color={dateObj.isWeekend ? 'error' : 'text.primary'}
                >
                  {dateObj
                    .toFormat('ccc', { locale: i18n.language })
                    .toUpperCase()}
                </Typography>
              </Box>
              <Box
                sx={{
                  background: (theme) => theme.palette.background.boxInPaper,
                  borderRadius: (theme) => `${theme.shape.borderRadius}px`,
                  padding: '4px',
                  width: `${DAY_WIDTH}px`,
                  height: `${DAY_WIDTH}px`,
                }}
              >
                {daySlots.slice(0, 1).map((slot, idx) => (
                  <Box key={idx}>
                    <Typography variant="paperBox">
                      {slot.start.toFormat('HH:mm')} -{' '}
                      {slot.end.toFormat('HH:mm')}
                    </Typography>
                    {daySlots.length > 1 && (
                      <Typography variant="paperBox">...</Typography>
                    )}
                  </Box>
                ))}
              </Box>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};

export default ScheduleList;
