import { Booking } from 'shared-ts/entities';
import { UserVM, ExperienceVM, BookingVM } from '../viewModels';
import { Experience } from 'shared-ts/entities';
import { User } from 'shared-ts/entities';
import { DateTime } from 'luxon';

export function bookingDbToVM(booking: Booking): BookingVM {
  return {
    id: booking.id,
    experienceId: booking.experienceId,
    name: booking.name,
    phone: booking.phone,
    timeSlot: {
      start: DateTime.fromMillis(booking.start),
      end: DateTime.fromMillis(booking.end),
    },
    email: booking.email,
    wishes: booking.wishes,
    viewed: booking.viewed,
    cancelledAt: booking.cancelledAt,
  } as BookingVM;
}

export function experienceDbToVM(experience: Experience): ExperienceVM {
  return {
    id: experience.id,
    accountId: experience.accountId,
    availabilities: experience.availabilities?.map((x) => ({
      start: DateTime.fromMillis(x.start),
      end: DateTime.fromMillis(x.end),
    })),
    limitSettings: experience.limitSettings,
    summary: {
      title: experience.title,
      description: experience.description,
      duration: experience.duration,
      link: experience.link,
    },
    googleCalendarSettings: experience.googleCalendarSettings,
  } as ExperienceVM;
}

export function experienceVMToDb(
  experienceVM: Partial<ExperienceVM>,
): Experience {
  return {
    id: experienceVM.id,
    accountId: experienceVM.accountId,
    ...(experienceVM.availabilities === null && {
      availabilities: null,
    }),
    ...(experienceVM.availabilities && {
      availabilities: experienceVM.availabilities?.map((x) => ({
        start: x.start.toMillis(),
        end: x.end.toMillis(),
      })),
    }),
    ...(experienceVM.limitSettings && {
      limitSettings: experienceVM.limitSettings,
    }),
    ...(experienceVM.summary?.title && { title: experienceVM.summary?.title }),
    ...(experienceVM.summary?.description && {
      description: experienceVM.summary?.description,
    }),
    ...(experienceVM.summary?.duration && {
      duration: experienceVM.summary?.duration,
    }),
    ...(experienceVM.summary?.link && { link: experienceVM.summary?.link }),
    ...(experienceVM.googleCalendarSettings === null && {
      googleCalendarSettings: null,
    }),
    ...(experienceVM.googleCalendarSettings && {
      googleCalendarSettings: {
        calendarIdForNewEvents:
          experienceVM.googleCalendarSettings?.calendarIdForNewEvents,
        calendarIdsForAvailability:
          experienceVM.googleCalendarSettings?.calendarIdsForAvailability
            ?.length === 0
            ? null
            : experienceVM.googleCalendarSettings?.calendarIdsForAvailability,
        createGoogleMeetLink:
          experienceVM.googleCalendarSettings?.createGoogleMeetLink,
        invitees:
          experienceVM.googleCalendarSettings?.invitees?.length === 0
            ? null
            : experienceVM.googleCalendarSettings?.invitees,
      },
    }),
  } as Experience;
}

export const userVMToDb = (user: Partial<UserVM>): Partial<User> => {
  return {
    ...(user.name && { name: user.name }),
    ...(user.email && { email: user.email }),
    ...(user.timezone && { timezone: user.timezone }),
    ...(user.language && { language: user.language }),
    ...(user.themeMode && { themeMode: user.themeMode }),
  };
};

export const bookingVMToDb = (
  booking: Partial<BookingVM>,
): Partial<Booking> => {
  return {
    id: booking.id,
    ...(booking.experienceId && { experienceId: booking.experienceId }),
    ...(booking.name && { name: booking.name }),
    ...(booking.phone && { phone: booking.phone }),
    ...(booking.timeSlot?.start && {
      start: booking.timeSlot.start.toMillis(),
    }),
    ...(booking.timeSlot?.end && { end: booking.timeSlot.end.toMillis() }),
    ...(booking.email && { email: booking.email }),
    ...(booking.wishes && { wishes: booking.wishes }),
    ...(booking.cancelledAt && { cancelledAt: booking.cancelledAt.toMillis() }),
  };
};
