import {
  Box,
  Avatar,
  Skeleton,
  Button,
  Typography,
  Divider,
} from '@mui/material';
import { useEffect, useState } from 'react';
import useExperiences from '../../../queryHelpers/useExperiences';
import { useAdminOutletContext } from '../AdminLayout';
import { ExperienceVM } from '../../../viewModels';
import { useTranslation } from 'react-i18next';
import CreateExperienceDrawer from './components/CreateExperienceDrawer';
import ExperienceDetailDrawer from './components/ExperienceDetailDrawer';
import AppCard from '../../../components/AppCard';
import ScheduleList from '../../../components/experiences/ScheduleList';

export default function ExperiencesPage() {
  const [openCreateExperience, setOpenCreateExperience] = useState(false);
  const [openEditExperience, setOpenEditExperience] = useState(false);
  const outletContext = useAdminOutletContext();
  const { t } = useTranslation();
  const [experienceId, setExperienceId] = useState<string | null>(null);

  useEffect(() => {
    outletContext.setAppBars({
      TopAppBar: {
        title: t('Services'),
      },
      BottomAppBar: {},
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  const { data, isFetched } = useExperiences();
  if (!isFetched) {
    return (
      <>
        <Skeleton variant="circular" width={50} height={50}>
          <Avatar />
        </Skeleton>
        <Skeleton />
      </>
    );
  }

  if (data?.length === 0) {
    return (
      <>
        <Box
          sx={{
            display: 'flex',
            gap: '16px',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          <Typography>
            {t('You do not have any services yet. Create your first service.')}
          </Typography>
          <Button
            variant="contained"
            onClick={() => setOpenCreateExperience(true)}
            fullWidth
          >
            {t('Create service')}
          </Button>
        </Box>

        <CreateExperienceDrawer
          formParams={{
            open: openCreateExperience,
            onClose: () => setOpenCreateExperience(false),
          }}
        />
      </>
    );
  }

  const handleEditClick = (experience: ExperienceVM) => {
    setExperienceId(experience.id ?? null);
    setOpenEditExperience(true);
    // setOpenCreateExperience(true);
    // navigate(routes.admin.experienceDetail(experience.id!));
  };

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        {data!.map((experience: ExperienceVM, index) => (
          <AppCard key={index} onClick={() => handleEditClick(experience!)}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
              <Typography variant="experienceCardHeader" sx={{ pl: '4px' }}>
                {experience.summary.title}
              </Typography>
              <Divider />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  maxWidth: '100%',
                }}
              >
                <Typography
                  sx={{
                    writingMode: 'vertical-lr',
                    position: 'absolute',
                    rotate: '180deg',
                    color: '#A4A9AC',
                    fontSize: '10px',
                    fontWeight: '400',
                    textAlign: 'center',
                    textTransform: 'uppercase',
                  }}
                >
                  {t('Schedule')}
                </Typography>
                <Box sx={{ pl: '24px', pr: '2px', overflowX: 'auto' }}>
                  <ScheduleList slots={experience.availabilities} />
                </Box>
              </Box>
            </Box>
          </AppCard>
        ))}

        <Button
          variant="contained"
          sx={{ width: 1 }}
          onClick={() => setOpenCreateExperience(true)}
        >
          {t('Create service')}
        </Button>
      </Box>

      <CreateExperienceDrawer
        formParams={{
          open: openCreateExperience,
          onClose: () => setOpenCreateExperience(false),
        }}
      />

      <ExperienceDetailDrawer
        formParams={{
          open: openEditExperience,
          onClose: () => setOpenEditExperience(false),
        }}
        experienceId={experienceId}
      />
      {/*
      <CreateExperienceDialog
        open={openCreateExperience}
        onClose={() => setOpenCreateExperience(false)}
      /> */}
    </>
  );
}
