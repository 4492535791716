import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormParams } from '../../types';
import { useEditForm } from '../../hooks/useEditForm';
import { createNewExperienceVM } from '../../viewModels';
import AppDrawer from '../AppDrawer';
import { LoadingButton } from '@mui/lab';
import useUpdateExperience from '../../queryHelpers/useUpdateExperience';
import { Box } from '@mui/material';
import useExperience from '../../queryHelpers/useExperience';
import SummaryEditor, { SummaryFormInputs, SummaryFormSchema } from './SummaryEditor';

interface Props {
  formParams: FormParams;
  experienceId: string;
}

export const SummaryEditDrawer: React.FC<Props> = ({
  formParams,
  experienceId,
}) => {
  const { t } = useTranslation();
  const { data } = useExperience({ id: experienceId, enabled: !!experienceId });

  const {
    form: {
      register,
      handleSubmit,
      watch,
      formState: { errors },
    },
  } = useEditForm<SummaryFormInputs>({
    defaultValues: {
      title: data?.summary.title,
      description: data?.summary.description,
      duration: data?.summary.duration,
      link: data?.summary.link,
    },
    formSchema: SummaryFormSchema(false),
  });

  const updateExperienceMutation = useUpdateExperience(experienceId);

  const handleSave = (data: SummaryFormInputs) => {
    const baseExperienceData = experienceId ? data : createNewExperienceVM();
    updateExperienceMutation.mutate(
      {
        ...baseExperienceData,
        summary: data,
      },
      {
        onSuccess: () => {
          formParams.onClose();
        },
        onError: () => {},
      },
    );
  };

  return (
    <AppDrawer formParams={formParams}>
      <form onSubmit={handleSubmit(handleSave)}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            mb: '16px',
          }}
        >
          <SummaryEditor
            autoLink={true}
            register={register}
            errors={errors}
            watch={watch}
            hideDuration={true}
          />
          <LoadingButton
            loading={updateExperienceMutation.isPending}
            fullWidth
            variant="contained"
            type="submit"
          >
            {t('Save')}
          </LoadingButton>
        </Box>
      </form>
    </AppDrawer>
  );
};

export default SummaryEditDrawer;
