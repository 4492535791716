import { Box, Avatar, Typography } from '@mui/material';
import { useUserContext } from '../../contexts/UserContext';
import { useTranslation } from 'react-i18next';
import LinkButton from '../LinkButton';
import UserProfileEditDrawer from './UserProfileEditDrawer';
import { useState } from 'react';

const UserInfo = () => {
  const [editDrawerOpen, setEditDrawerOpen] = useState(false);
  const { user } = useUserContext();
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      {/* Edit Button */}
      <Box
        sx={{
          position: 'absolute',
          right: 0,
          top: 0,
          zIndex: 1,
        }}
      >
        <LinkButton to="/settings/profile" onClick={() => setEditDrawerOpen(true)}>{t('Edit')}</LinkButton>
      </Box>

      {/* Main Content Box with Border */}
      <Box
        sx={{
          mt: '16px',
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <Avatar
          sx={{
            width: 80,
            height: 80,
            fontSize: '2rem'
          }}
        >
          {user?.name?.charAt(0) || 'A'}
        </Avatar>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            variant="h6"
            sx={{
              fontSize: '24px',
              fontWeight: 500,
              lineHeight: '28.13px',
            }}
          >
            {user?.name}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              fontSize: '14px',
              lineHeight: '16.41px',
              fontWeight: 300,
            }}
          >
            {user?.email}
          </Typography>
        </Box>
      </Box>

      <UserProfileEditDrawer
        formParams={{
          onClose: () => setEditDrawerOpen(false),
          open: editDrawerOpen,
        }}
      />
    </Box>
  );
};

export default UserInfo;
