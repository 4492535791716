import { useMemo } from 'react';
import { FormParams } from '../../types';
import { useTranslation } from 'react-i18next';
import PickerDrawer, { PickerOption } from '../PickerDrawer';

interface DurationPickerDrawerProps {
  formParams: FormParams;
  onSelect: (minutes: number) => Promise<{ error?: string }>;
  value?: number;
}

const FutureBookingDaysLimitPickerDrawer: React.FC<DurationPickerDrawerProps> = ({
  formParams,
  onSelect,
  value,
}) => {
  const { t } = useTranslation();

  // Generate time durations (0 to 90 days)
  const options = useMemo(() => {
    const durations: PickerOption<number>[] = [];
    for (let days = 1; days <= 90; days++) {
      const duration = `${t('day_count', { count: days })}`.trim();
      durations.push({ text: duration, value: days });
    }
    return durations;
  }, [t]);

  return (
    <PickerDrawer
      formParams={formParams}
      title={t('Booking window')}
      description={t('The schedule for clients is open only for the next _count_other', {
        count: value,
      })}
      options={options}
      value={value}
      onSelect={onSelect}
      confirmText={t('Select')}
    />
  );
};

export default FutureBookingDaysLimitPickerDrawer;
