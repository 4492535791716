// site/theme.ts

import { createTheme, ThemeOptions } from '@mui/material/styles';
import * as rootTheme from '../../theme/light';
import { merge } from 'lodash';

const customTheme: ThemeOptions = {
  components: {

  },
};

// Default theme https://mui.com/material-ui/customization/default-theme/
// Standard https://mui.com/material-ui/customization/default-theme/?expand-path=$.palette
const theme = createTheme({
  ...merge({}, rootTheme.default, customTheme),
});

export default theme;
