import { Box } from '@mui/material';
import {
  Outlet,
  useOutletContext,
} from 'react-router-dom';
import { useEffect, useRef } from 'react';
// Import icons for the bottom navigation
import { AppDrawerProvider } from '../../components/AppDrawerContext';

export type TopAppBarActionItem = {
  icon: React.ReactNode;
  label: string;
  onClick: () => void;
};

export type TopAppBarProps = {
  title: string;
  backButtonPath?: string;
  actionItems?: TopAppBarActionItem[];
  fab?: BottomAppBarFab;
  hide?: boolean;
};

export type BottomAppBarFab = {
  title: string;
  icon: React.ReactNode;
  onClick: () => void;
};

export type BottomAppBarProps = {
  fab?: BottomAppBarFab;
};

export type AppBarsProps = {
  TopAppBar?: TopAppBarProps;
  BottomAppBar?: BottomAppBarProps;
};

export type AdminOutletContext = {
  setAppBars: (props: AppBarsProps) => void;
};

export function useAdminOutletContext() {
  return useOutletContext<AdminOutletContext>();
}

export default function BookLayout() {
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      const { offsetWidth, offsetHeight } = containerRef.current;
      console.log(`Container size: ${offsetWidth} x ${offsetHeight}`);
    }
  }, []);

  return (
    <AppDrawerProvider
      value={{
        paperProps: {
          sx: {
            maxWidth: '400px',
            margin: '0 auto',
          },
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: 'calc(var(--vh, 1vh) * 100)',
          width: '100%',
          alignItems: 'center',
          paddingTop: 'env(safe-area-inset-top)',
          paddingBottom: 'env(safe-area-inset-bottom)',
          boxSizing: 'border-box',
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            overflowY: 'auto',
            ml: '16px',
            mr: '16px',
            mb: 7,
            '&::-webkit-scrollbar': {
              display: 'none',
            },
            pt: { xs: 2, sm: 2 },
            pb: 2,
          }}
        >
          <Box
            sx={{
              pt: 'max(env(safe-area-inset-top), 0px)',
              pb: 'max(env(safe-area-inset-bottom), 16px)',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box component="main" sx={{ maxWidth: '400px' }}>
              <Outlet />
            </Box>
          </Box>
        </Box>
      </Box>
    </AppDrawerProvider>
  );
}
