import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import AppDrawer from '../AppDrawer';
import AppTransparentCard from '../AppTransparentCard';
import AppCardButton from '../AppCardButton';
import AppErrorButton from '../AppErrorButton';
import { FormParams } from '../../types';
import useBooking from '../../queryHelpers/useBooking';
import useExperience from '../../queryHelpers/useExperience';
import AppCard from '../AppCard';
import { useTimeZone } from '../../contexts/TimeZoneContext';
import CancelBookingsConfirmationDrawer from './CancelBookingsConfirmationDrawer';
import BookingClientEditDrawer from './BookingClientEditDrawer';
import BookingScheduleEditDrawer from './BookingScheduleEditDrawer';
import { isMobile } from 'react-device-detect';

type Props = {
  formParams: FormParams;
  bookingId: string | null;
  onCall?: () => void;
  onCancel?: () => void;
};

const BookingDetailDrawer: React.FC<Props> = ({
  formParams,
  bookingId,
  onCall,
}) => {
  const { t } = useTranslation();
  const { timezone } = useTimeZone();
  const bookingQuery = useBooking(bookingId ?? undefined, !!bookingId);
  const experienceQuery = useExperience({
    id: bookingQuery.data?.experienceId!,
    enabled: !!bookingQuery.data?.experienceId,
  });
  const [isCancelConfirmationOpen, setIsCancelConfirmationOpen] =
    useState(false);
  const [isClientEditOpen, setIsClientEditOpen] = useState(false);
  const [isScheduleEditOpen, setIsScheduleEditOpen] = useState(false);
  const handleCall = () => {
    if (bookingQuery.data?.phone) {
      if (isMobile) {
        window.location.href = `tel:${bookingQuery.data?.phone}`;
      } else {
        alert(
          `To call ${bookingQuery.data?.phone}, please use your phone app.`,
        );
      }
    }
    onCall?.();
  };

  const handleEmail = () => {
    if (bookingQuery.data?.email) {
      window.location.href = `mailto:${bookingQuery.data?.email}`;
    }
  };

  return (
    <AppDrawer
      formParams={formParams}
      title={experienceQuery.data?.summary.title}
      titlePosition="center"
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          mb: '16px',
        }}
      >
        {/* Date and Time */}
        <AppCard
          title={t('Date and time')}
          onClick={!bookingQuery.data?.cancelledAt ? () => setIsScheduleEditOpen(true) : undefined}
          badge={bookingQuery.data?.cancelledAt ? {
            text: t('Cancelled'),
            color: 'error'
          } : undefined}
        >
          <Typography variant="body1">
            {bookingQuery.data?.timeSlot.start
              .setZone(timezone)
              .toFormat('dd MMMM')}{' '}
            (
            {bookingQuery.data?.timeSlot.start
              .setZone(timezone)
              .toFormat('HH:mm')}{' '}
            -{' '}
            {bookingQuery.data?.timeSlot.end
              .setZone(timezone)
              .toFormat('HH:mm')}
            )
          </Typography>
        </AppCard>

        {/* Client Info */}
        <AppCard title={t('Client')} onClick={() => setIsClientEditOpen(true)}>
          <Typography variant="body1">{bookingQuery.data?.name}</Typography>
          <Typography variant="body1">{bookingQuery.data?.email}</Typography>
          <Typography variant="body1">{bookingQuery.data?.phone}</Typography>
        </AppCard>

        {/* Call Button */}
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
            {bookingQuery.data?.phone && isMobile && (
              <AppCardButton
                iconPath={'/images/phone.svg'}
                text={t('Phone call')}
                onClick={handleCall}
              />
            )}
            {bookingQuery.data?.email && (
              <AppCardButton
                iconPath={'/images/mail.svg'}
                text={t('Send email')}
                onClick={handleEmail}
              />
            )}
          </Box>
        {/* Comments */}
        {bookingQuery.data?.wishes && (
          <AppTransparentCard title={t('Comments')}>
            <Typography variant="body1">{bookingQuery.data?.wishes}</Typography>
          </AppTransparentCard>
        )}

          <AppErrorButton
            text={t('Cancel appointment')}
            disabled={!!bookingQuery.data?.cancelledAt}
            onClick={() => setIsCancelConfirmationOpen(true)}
          />
      </Box>

      <BookingClientEditDrawer
        formParams={{
          open: isClientEditOpen,
          onClose: () => setIsClientEditOpen(false),
        }}
        bookingId={bookingId}
      />

      <BookingScheduleEditDrawer
        formParams={{
          open: isScheduleEditOpen,
          onClose: () => setIsScheduleEditOpen(false),
        }}
        bookingId={bookingId}
      />

      <CancelBookingsConfirmationDrawer
        formParams={{
          open: isCancelConfirmationOpen,
          onClose: () => setIsCancelConfirmationOpen(false),
        }}
        bookingId={bookingId}
        onCancel={() => formParams.onClose()}
      />
    </AppDrawer>
  );
};

export default BookingDetailDrawer;
