import { useMemo } from 'react';
import { FormParams } from '../../types';
import { useTranslation } from 'react-i18next';
import { PickerOption } from '../PickerDrawer';
import useGoogleCalendars from '../../queryHelpers/useGoogleCalendars';
import useUpdateExperience from '../../queryHelpers/useUpdateExperience';
import useExperience from '../../queryHelpers/useExperience';
import MultiSelectDrawer from '../MultiSelectDrawer';

interface Props {
  formParams: FormParams;
  experienceId: string;
}

const GoogleCalendarAvailabilitySelectDrawer: React.FC<Props> = ({
  formParams,
  experienceId,
}) => {
  const { t } = useTranslation();
  const googleCalendarsQuery = useGoogleCalendars();
  const experienceQuery = useExperience({ id: experienceId, enabled: !!experienceId });
  const updateExperienceMutation = useUpdateExperience(experienceId);

  const options = useMemo(() => {
    return (
      googleCalendarsQuery.data?.map(
        (x) => ({ text: x.summary!, value: x.id! }) as PickerOption<string>,
      ) || []
    );
  }, [googleCalendarsQuery.data]);

  const handleSelect = async (ids: string[]) => {
    try {
      await updateExperienceMutation.mutateAsync({
        id: experienceId!,
        googleCalendarSettings: {
          ...{
            invitees: [],
            calendarIdForNewEvents: null,
            createGoogleMeetLink: false,
            calendarIdsForAvailability: [],
          },
          ...experienceQuery.data!.googleCalendarSettings,
          calendarIdsForAvailability: ids,
        },
      });
      return {};
    } catch (error) {
      return {
        error: t('Failed to update Google calendars for checking availability'),
      };
    }
  };

  return (
    <MultiSelectDrawer
      formParams={formParams}
      title={t('Check availability in')}
      options={options}
      value={experienceQuery.data?.googleCalendarSettings?.calendarIdsForAvailability ?? []}
      onSelect={handleSelect}
      confirmText={t('Select')}
    />
  );
};

export default GoogleCalendarAvailabilitySelectDrawer;
