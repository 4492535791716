import { useMemo } from 'react';
import { FormParams } from '../../types';
import { useTranslation } from 'react-i18next';
import PickerDrawer, { PickerOption } from '../PickerDrawer';
import { minutesToTime } from '../../helpers/pluralHelpers';

const scale = [
  10, 20, 30, 40, 50, 60, 90, 120, 180, 240, 300, 360, 420, 480, 540, 600, 660,
  720, 780, 840, 900, 960, 1020, 1080, 1140, 1200, 1260, 1320, 1380, 1440, 2880,
  4320, 5760, 7200, 8640, 10080, 11520, 12960, 14400, 15840, 17280, 18720,
  20160,
];

interface Props {
  formParams: FormParams;
  onSelect: (minutes: number) => Promise<{ error?: string }>;
  value?: number;
}

const MinimumNoticePeriodPickerDrawer: React.FC<Props> = ({
  formParams,
  onSelect,
  value,
}) => {
  const { t } = useTranslation();

  // Generate time durations based on the minute scale
  const options = useMemo(() => {
    const durations: PickerOption<number>[] = scale.map((minutes) => {
      return {
        text: minutesToTime(minutes),
        value: minutes,
      };
    });
    return durations;
  }, []);

  return (
    <PickerDrawer
      formParams={formParams}
      title={t('Minimum notice')}
      description={t('Clients can register no later than')}
      options={options}
      value={value}
      onSelect={onSelect}
      confirmText={t('Select')}
    />
  );
};

export default MinimumNoticePeriodPickerDrawer;
