import { Box, Card, CardContent, CardProps, Typography, Chip } from '@mui/material';
import { MouseEventHandler, PropsWithChildren, useState } from 'react';
import darkTheme from '../theme/dark';

export type SecondaryAction = {
  title: string;
  onClick: () => void;
};

export type BadgeProps = {
  text: string;
  color?: 'error' | 'warning' | 'info' | 'success';
};

type AppCardProps = PropsWithChildren & {
  cardProps?: CardProps;
  title?: string;
  onClick?: MouseEventHandler<HTMLDivElement> | undefined;
  secondaryAction?: SecondaryAction;
  iconPath?: string;
  badge?: BadgeProps;
};

const AppCard: React.FC<AppCardProps> = (props) => {
  const [isClicked, setIsClicked] = useState(false);

  const clickableCardProps: CardProps = {
    onMouseDown: () => setIsClicked(true),
    onMouseUp: () => setIsClicked(false),
    onMouseLeave: () => setIsClicked(false),
    onTouchStart: () => setIsClicked(true),
    onTouchEnd: () => setIsClicked(false),
    sx: {
      transform: isClicked ? 'scale(0.99)' : 'scale(1)',
      transition: 'transform 0.1s',
      cursor: 'pointer',
    },
    onClick: props.onClick,
  };

  return (
    <Box>
      <Card
        {...props.cardProps}
        {...(props.onClick && { ...clickableCardProps, sx: undefined })}
        sx={{
          position: 'relative',
          transform: props.onClick && isClicked ? 'scale(0.99)' : 'scale(1)',
          transition: props.onClick ? 'transform 0.1s' : undefined,
          cursor: props.onClick ? 'pointer' : undefined,
          ...(props.cardProps?.sx || {})
        }}
      >
        {props.badge && (
          <Box sx={{ position: 'absolute', top: 8, right: 8, zIndex: 1 }}>
            <Chip
              label={props.badge.text}
              color={props.badge.color || 'default'}
              size="small"
            />
          </Box>
        )}
        <CardContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            {(props.title || props.secondaryAction || props.iconPath) && (
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {props.title && (
                  <Typography variant="cardHeader">{props.title}</Typography>
                )}
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  {props.iconPath && (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <img src={props.iconPath} alt="Icon" />
                    </Box>
                  )}
                  {props.secondaryAction && (
                    <Typography
                      variant="caption"
                      sx={{
                        color: darkTheme.palette.action.active,
                        cursor: 'pointer',
                      }}
                      onClick={props.secondaryAction.onClick}
                    >
                      {props.secondaryAction.title}
                    </Typography>
                  )}
                </Box>
              </Box>
            )}
            <Box>{props.children}</Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default AppCard;
