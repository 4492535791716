import toast from 'react-hot-toast';
import { routes } from '../routes';
import { ExperienceVM } from '../viewModels';
import { t } from 'i18next';

export function getBookLink(experience: ExperienceVM) {
  const baseUrl = `${window.location.protocol}//${window.location.host}`;
  return `${baseUrl}${routes.booking(
    experience.summary.link || experience.id,
  )}`;
}

export function canShareBookLink() {
  return navigator.share;
}

export async function shareBookLink(
  experience: ExperienceVM,
  copyToClipboard: boolean = false,
) {
  if (navigator.share && !copyToClipboard) {
    try {
      const shareText = t('Schedule your appointment for {{experienceName}}', {
        experienceName: experience!.summary.title,
      });
      const shareUrl = getBookLink(experience!);

      await navigator.share({
        title: t('Book Your Appointment'),
        text: `${shareText}\n\n${shareUrl}`,
        url: shareUrl,
      });
    } catch (error) {
      console.error('Error sharing link:', error);
    }
    return;
  } else {
    navigator.clipboard.writeText(
      `${t('Book your appointment for {{experienceName}}', {
        experienceName: experience!.summary.title,
      })}\n${getBookLink(experience!)}`,
    );
    toast.success(
      t('Booking link copied! You can now share it with your client.'),
    );
  }
}
