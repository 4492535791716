import React from 'react';
import { TextField, Slider, FormHelperText, Box } from '@mui/material';
import { pluralTime } from '../../helpers/pluralHelpers';
import { Label } from '../../styled/Label';
import { FieldErrors, UseFormRegister, UseFormWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Field } from '../../styled/Field';
import { SummaryVM } from '../../viewModels';
import * as yup from 'yup';

export const SummaryFormSchema = (showLink: boolean = true) =>
  yup
    .object({
      title: yup.string().required('Enter a name'),
      description: yup.string(),
      duration: yup.number().required('Enter a duration'),
      link: showLink
        ? yup
            .string()
            .required('Enter a link')
            .max(25, 'Enter a shorter link')
            .matches(/^[a-zA-Z0-9-]+$/, 'Use only Latin letters and numbers')
        : yup.string(),
    })
    .required();

export type SummaryFormInputs = SummaryVM;

interface SummaryEditorProps {
  register: UseFormRegister<SummaryFormInputs>;
  errors: FieldErrors<SummaryFormInputs>;
  watch: UseFormWatch<SummaryFormInputs>;
  autoLink?: boolean;
  hideDuration?: boolean;
}

const SummaryEditor: React.FC<SummaryEditorProps> = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
        <Field label={t('Title')} errorMessage={props.errors.title?.message}>
          <TextField
            size="small"
            error={!!props.errors.title}
            {...props.register('title')}
            fullWidth
          />
        </Field>
        <Field
          label={t('Description')}
          errorMessage={props.errors.description?.message}
        >
          <TextField
            size="small"
            {...props.register('description')}
            multiline
            rows={3}
            fullWidth
          />
        </Field>
        {!props.hideDuration && (
          <Field
            label={t('Duration')}
            errorMessage={props.errors.duration?.message}
          >
            <Label>
              {t('Duration')} {pluralTime(props.watch('duration')!)}{' '}
            </Label>
            <Slider
              {...props.register('duration')}
              step={5}
              min={5}
              max={720}
            />
          </Field>
        )}
        {!props.autoLink && (
          <Field label={t('Link')} errorMessage={props.errors.link?.message}>
            <TextField
              size="small"
              error={!!props.errors.link}
              {...props.register('link', {
                required: { value: true, message: t('Enter a link') },
              })}
              fullWidth
            />
            <FormHelperText>
              http://Minutus.com/book/{props.watch('link')}
            </FormHelperText>
          </Field>
        )}
      </Box>
    </>
  );
};

export default SummaryEditor;
