import { Box, Button } from '@mui/material';
import {
  MouseEventHandler,
} from 'react';
import { AppIcon } from './AppIcon';

type AppCardButtonProps = {
  iconPath: string;
  text: string;
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
  disabled?: boolean;
};

const AppCardButton: React.FC<AppCardButtonProps> = (props) => {
  return (
    <Button
      disableFocusRipple
      disabled={props.disabled}
      sx={{
        textTransform: 'none',
        backgroundColor: (theme) => (theme.components?.MuiCard?.styleOverrides?.root as any).backgroundColor,
        color: (theme) => theme.palette.text.primary,
        fontSize: '10px',
        fontWeight: 300,
        lineHeight: '11.72px',
        cursor: props.disabled ? 'not-allowed' : 'pointer',
        py: '18px',
        px: '8px',
        width: 1,
        opacity: props.disabled ? 0.5 : 1,
        '&:hover': {
          backgroundColor: (theme) => (theme.components?.MuiCard?.styleOverrides?.root as any).backgroundColor,
        }
      }}
      onClick={props.onClick}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          gap: '8px',
          px: '16px'
        }}
      >
        <AppIcon src={props.iconPath} size="20px" />
        {props.text}
      </Box>
    </Button>
  );
};

export default AppCardButton;
