import plural from 'plural-ru';
import i18n from '../i18n';

export function pluralDays(value: number) {
  return `${value} ${plural(
    value,
    i18n.t('day0'),
    i18n.t('day1'),
    i18n.t('day2'),
  )}`;
}

export function pluralBookings(value: number) {
  return `${value} ${plural(
    value,
    i18n.t('Appointment0'),
    i18n.t('Appointment1'),
    i18n.t('Appointment2'),
  )}`;
}

export function pluralCredits(value: number) {
  return `${value} ₽`;
}

export function pluralTime(minutes: number) {
  const days = Math.floor(minutes / 1440);
  const hoursLeft = Math.floor((minutes - days * 1440) / 60);
  const minutesLeft = minutes - days * 1440 - hoursLeft * 60;

  let result =
    days > 0
      ? days +
        ' ' +
        plural(days, i18n.t('day0'), i18n.t('day1'), i18n.t('day2'))
      : '';
  result +=
    hoursLeft > 0
      ? (result ? ' ' : '') +
        hoursLeft +
        ' ' +
        plural(hoursLeft, i18n.t('hour0'), i18n.t('hour1'), i18n.t('hour2'))
      : '';
  result +=
    minutesLeft > 0
      ? (result ? ' ' : '') +
        minutesLeft +
        ' ' +
        plural(
          minutesLeft,
          i18n.t('minute0'),
          i18n.t('minute1'),
          i18n.t('minute2'),
        )
      : '';
  return result;
}

export function minutesToTime(minutes: number) {
  let displayText = '';
  const days = Math.floor(minutes / 1440);
  const hours = Math.floor((minutes % 1440) / 60);
  const mins = minutes % 60;

  if (days > 0) {
    displayText += `${i18n.t('day_count', { count: days })}`;
  }
  if (hours > 0) {
    displayText += `${displayText ? ' ' : ''}${i18n.t('hour_count', {
      count: hours,
    })}`;
  }
  if (mins > 0) {
    displayText += `${displayText ? ' ' : ''}${i18n.t('minute_count', {
      count: mins,
    })}`;
  }

  return displayText || `${i18n.t('Without break')}`;
}

export function enabled(value: boolean) {
  return value ? i18n.t('Enabled') : i18n.t('Disabled');
}
