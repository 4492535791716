import { Box } from '@mui/material';
import AppDrawer from '../AppDrawer';
import { FormParams } from '../../types';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import { useEditForm } from '../../hooks/useEditForm';
import useBooking from '../../queryHelpers/useBooking';
import { LoadingButton } from '@mui/lab';
import { TextField } from '@mui/material';
import { Field } from '../../styled/Field';
import * as yup from 'yup';
import useUpsertBooking from '../../queryHelpers/useUpsertBooking';
import { BookingVM } from '../../viewModels';
import { Controller } from 'react-hook-form';
import PhoneInput from '../PhoneInput';

interface ClientEditDrawerProps {
  formParams: FormParams;
  bookingId: string | null;
}

export const BookingFormSchema = yup
  .object({
    name: yup.string().required(),
    email: yup.string().email(),
    phone: yup.string(),
  })
  .required();

export type BookingFormInputs = Pick<BookingVM, 'name' | 'email' | 'phone'>;

const BookingClientEditDrawer: React.FC<ClientEditDrawerProps> = (props) => {
  const { t } = useTranslation();
  const bookingQuery = useBooking(props.bookingId ?? undefined);
  const upsertBookingMutation = useUpsertBooking();

  const { form } = useEditForm<BookingFormInputs>({
    defaultValues: {
      name: bookingQuery.data?.name ?? '',
      email: bookingQuery.data?.email ?? '',
      phone: bookingQuery.data?.phone ?? '',
    },
    formSchema: BookingFormSchema,
  });

  useEffect(() => {
    if (bookingQuery.data) {
      form.reset({
        name: bookingQuery.data.name,
        email: bookingQuery.data.email,
        phone: bookingQuery.data.phone,
      });
    }
  }, [bookingQuery.data, form]);

  const onSubmit = async (data: BookingFormInputs) => {
    await upsertBookingMutation.mutateAsync({
      ...data,
      id: props.bookingId!,
    });
    props.formParams.onClose();
  };

  if (!form) return null;

  return (
    <AppDrawer title={t('Client')} formParams={props.formParams}>
      <Box sx={{ mb: '16px' }}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
            }}
          >
            <Field
              label={t('Name')}
              errorMessage={form.formState.errors.name?.message}
            >
              <TextField
                size="small"
                error={!!form.formState.errors.name}
                {...form.register('name')}
                fullWidth
              />
            </Field>

            <Field
              label={t('Email')}
              errorMessage={form.formState.errors.email?.message}
            >
              <TextField
                size="small"
                error={!!form.formState.errors.email}
                {...form.register('email')}
                fullWidth
              />
            </Field>

            <Field
              label={t('Phone')}
              errorMessage={form.formState.errors.phone?.message}
            >
              <Controller
                name="phone"
                control={form.control}
                render={({ field }) => (
                  <PhoneInput
                    value={field.value}
                    onChange={(value) => field.onChange(value)}
                    error={!!form.formState.errors.phone}
                  />
                )}
              ></Controller>
            </Field>

            <LoadingButton
              variant="contained"
              color="primary"
              type="submit"
              loading={upsertBookingMutation.isPending}
            >
              {t('Save')}
            </LoadingButton>
          </Box>
        </form>
      </Box>
    </AppDrawer>
  );
};

export default BookingClientEditDrawer;
