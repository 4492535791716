import { Box, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AppDrawer from './AppDrawer';
import { useState, useRef, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';

export interface PickerOption<T> {
  text: string;
  value: T;
}

interface PickerDrawerProps<T> {
  formParams: any; // Adjust type as necessary for formParams
  title: string;
  description?: string;
  options: PickerOption<T>[];
  value?: T;
  onSelect: (totalMinutes: T) => Promise<{ error?: string }>;
  confirmText: string;
}

const PickerDrawer = <T extends any>(props: PickerDrawerProps<T>) => {
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();

  // Initialize selected index based on value
  useEffect(() => {
    if (props.value === undefined || props.options.length === 0) return;
    const index = props.options.findIndex((d) => d.value === props.value);
    setSelectedIndex(index);
    if (scrollRef.current && index !== -1) {
      const item = scrollRef.current.children[index] as HTMLElement;
      item.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value, props.options, scrollRef.current]);

  const handleSelect = async () => {
    if (selectedIndex !== null) {
      setIsLoading(true);
      setError(null);

      try {
        const result = await props.onSelect(props.options[selectedIndex].value);
        if (result.error) {
          setError(result.error);
        } else {
          props.formParams.onClose();
        }
      } catch (err: unknown) {
        setError('An unknown error occurred');
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleDurationClick = (index: number) => {
    setSelectedIndex(index);
    if (scrollRef.current) {
      const item = scrollRef.current.children[index] as HTMLElement;
      item.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <AppDrawer
      formParams={props.formParams}
      title={props.title}
      description={props.description}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          mb: '16px',
        }}
      >
        <Box sx={{ my: '32px' }}>
          <Box
            ref={scrollRef}
            sx={{
              maxHeight: 300,
              overflowY: 'auto',
              '&::-webkit-scrollbar': { display: 'none' },
              scrollSnapType: 'y mandatory',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {props.options.map((item, index) => (
              <Typography
                key={item.value as string}
                onClick={() => handleDurationClick(index)}
                sx={{
                  py: '5px',
                  px: '5px',
                  color: selectedIndex === index
                    ? theme.palette.picker.textSelected
                    : theme.palette.picker.textUnselected,
                  cursor: 'pointer',
                  scrollSnapAlign: 'center',
                  fontSize: '16px',
                  textAlign: 'center',
                }}
              >
                {item.text}
              </Typography>
            ))}
          </Box>

          {error && (
            <Typography
              sx={{
                color: 'error.main',
                mb: 2,
                textAlign: 'center',
              }}
            >
              {error}
            </Typography>
          )}
        </Box>

        <LoadingButton
          type="submit"
          variant="contained"
          onClick={handleSelect}
          loading={isLoading}
          fullWidth
        >
          {props.confirmText}
        </LoadingButton>
      </Box>
    </AppDrawer>
  );
};

export default PickerDrawer;
