import { useNavigate, useParams } from 'react-router-dom';
import React, {  } from 'react';

import BookingForm from '../../components/bookingForm/BookingForm';

interface BookingProcessPageProps {}

const BookingProcessPage: React.FC<BookingProcessPageProps> = (props) => {
  const { experienceId } = useParams<{ experienceId: string }>();
  const navigate = useNavigate();
  const handleSave = (id: string) => {
    navigate(`/booking/${id}/success`);
  };

  return (
    <>
      {experienceId && (
        <BookingForm experienceId={experienceId} onSave={handleSave} />
      )}
    </>
  );
};

export default BookingProcessPage;
