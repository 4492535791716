import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Box } from '@mui/material';
import AppDrawer from '../AppDrawer';
import * as yup from 'yup';
import { BookingVM } from '../../viewModels';
import { useEditForm } from '../../hooks/useEditForm';
import BookingClientEditor, { BookingClientFormInputs } from './BookingClientEditor';
import BookingScheduleEditor, { BookingScheduleFormInputs } from './BookingScheduleEditor';
import useUpsertBooking from '../../queryHelpers/useUpsertBooking';
import toast from 'react-hot-toast';
import { Trans, useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { FormParams } from '../../types';
import { LoadingButton } from '@mui/lab';
import useExperience from '../../queryHelpers/useExperience';

type CreateBookingsDrawerProps = {
  formParams: FormParams;
  experienceId: string | null;
  onBookingCreated: (booking: Omit<BookingVM, 'createdIn'>) => void;
};

const Schema = yup
  .object({
    name: yup.string().required(i18n.t('Enter name')),
    phone: yup
      .string()
      .min(11, i18n.t('Enter full phone number'))
      .required(i18n.t('Enter phone number')),
    timeSlot: yup.object().required(i18n.t('Select time')),
  })
  .required();

export type CreateBookingFormInputs = Omit<
  BookingVM,
  'id' | 'experience' | 'createdIn' | 'experienceId'
>;

const CreateBookingsDrawer: React.FC<CreateBookingsDrawerProps> = (props) => {
  const { t } = useTranslation();
  const experienceQuery = useExperience({
    id: props.experienceId!,
    link: props.experienceId!,
  });
  const appForm = useEditForm<CreateBookingFormInputs>({
    formSchema: Schema,
    defaultValues: {
      name: '',
      phone: '',
      date: null,
      timeSlot: null,
    },
  });

  const upsertBookingMutation = useUpsertBooking();

  const handleSubmitted = async () => {
    await appForm.form.trigger();
    if (!appForm.form.formState.isValid) {
      return;
    }


    const values = appForm.form.getValues();
    await upsertBookingMutation.mutateAsync(
      {
        experienceId: props.experienceId!,
        ...values,
      },
      {
        onSuccess: (booking) => {
          props.formParams.onClose();
          toast.success(t('Booking created successfully'));
          appForm.form.reset();
          console.log(values);
          props.onBookingCreated({
            ...values,
            id: booking,
            experienceId: props.experienceId!,
          });
        },
        onError: (error) => {
          toast.error(error.message);
        },
      },
    );
  };

  return (
    <AppDrawer
      formParams={props.formParams}
      height="full"
      title={
        <Trans
          i18nKey="New booking for {{experienceName}}"
          values={{ experienceName: experienceQuery.data?.summary.title }}
        />
      }
    >
      <form onSubmit={appForm.form.handleSubmit(handleSubmitted)}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            pb: '16px',
          }}
        >
          <BookingScheduleEditor
            form={
              appForm.form as unknown as UseFormReturn<BookingScheduleFormInputs>
            }
            experienceId={props.experienceId!}
          />
          <BookingClientEditor
            form={
              appForm.form as unknown as UseFormReturn<BookingClientFormInputs>
            }
          />
          <LoadingButton
            loading={upsertBookingMutation.isPending}
            fullWidth
            variant="contained"
            type="submit"
          >
            {t('Create booking')}
          </LoadingButton>
        </Box>
      </form>
    </AppDrawer>
  );
};

export default CreateBookingsDrawer;
