import React, { useState, useEffect } from 'react';
import AppCard from '../AppCard';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import BookmarkInstructionDrawer from './BookmarkInstructionDrawer';

type AddBookmarkCardProps = {
  pageTitle?: string;
  pageUrl?: string;
};

const AddBookmarkCard: React.FC<AddBookmarkCardProps> = ({
  pageTitle = document.title,
}) => {
  const { t } = useTranslation();
  const pageUrl = window.location.href;
  const [isBookmarkInstructionOpen, setIsBookmarkInstructionOpen] =
    useState(false);

  const handleAddToBookmarks = () => {
    // Attempt to add bookmark programmatically
    try {
      // Firefox
      if (window.sidebar && window.sidebar.addPanel) {
        window.sidebar.addPanel(pageTitle, pageUrl, '');
        return;
      }

      // Internet Explorer
      if (
        window.external &&
        typeof (window.external as any).AddFavorite === 'function'
      ) {
        (window.external as any).AddFavorite(pageUrl, pageTitle);
        return;
      }

      // Chrome/Edge/Modern Browsers
      if (navigator.clipboard) {
        navigator.clipboard.writeText(pageUrl);
      }

      // If automatic methods fail, open instruction drawer
      setIsBookmarkInstructionOpen(true);
    } catch (error) {
      // If any error occurs, open instruction drawer
      console.warn('Automatic bookmark failed:', error);
      setIsBookmarkInstructionOpen(true);
    }
  };

  useEffect(() => {
    // Attempt to add bookmark automatically on first render
    const hasBookmarkedBefore = localStorage.getItem('bookmarkAttempted');

    if (!hasBookmarkedBefore) {
      handleAddToBookmarks();
      localStorage.setItem('bookmarkAttempted', 'true');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <AppCard
        title={t('Add to bookmarks')}
        onClick={handleAddToBookmarks}
        iconPath="/images/add_bookmark.svg"
      >
        <Typography variant="body1">
          {t(
            'Add this page to your bookmarks to quickly access your appointment details.',
          )}
        </Typography>
      </AppCard>

      <BookmarkInstructionDrawer
        formParams={{
          open: isBookmarkInstructionOpen,
          onClose: () => setIsBookmarkInstructionOpen(false),
        }}
        shortcut={navigator.platform.indexOf('Mac') !== -1 ? '⌘+D' : 'Ctrl+D'}
      />
    </>
  );
};

export default AddBookmarkCard;
