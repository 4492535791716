import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormParams } from '../../types';
import { useEditForm } from '../../hooks/useEditForm';
import AppDrawer from '../AppDrawer';
import { LoadingButton } from '@mui/lab';
import { Box, TextField } from '@mui/material';
import { Field } from '../../styled/Field';
import { useUserContext } from '../../contexts/UserContext';
import useUpdateUserSettings from '../../queryHelpers/useUpdateUserSettings';
import * as yup from 'yup';
import i18n from '../../i18n';
import { toast } from 'react-hot-toast';

interface Props {
  formParams: FormParams;
}

const UserProfileFormSchema = yup.object({
  name: yup.string().required(i18n.t('Name is required')),
  email: yup
    .string()
    .email(i18n.t('Invalid email address'))
    .required(i18n.t('Email is required')),
});

type UserProfileFormInputs = yup.InferType<typeof UserProfileFormSchema>;

export const UserProfileEditDrawer: React.FC<Props> = ({ formParams }) => {
  const { t } = useTranslation();
  const { user } = useUserContext();
  const updateUserMutation = useUpdateUserSettings();

  const { form } = useEditForm<UserProfileFormInputs>({
    defaultValues: {
      name: user?.name,
      email: user?.email,
    },
    formSchema: UserProfileFormSchema,
  });

  const handleSave = async (data: UserProfileFormInputs) => {
    try {
      await updateUserMutation.mutateAsync(
        {
          id: user?.id,
        name: data.name,
      },
      {
        onSuccess: () => {
          formParams.onClose();
        },
        onError: () => {},
      },
      );
    } catch (error) {
      toast.error(t('Failed to update name'));
    }
  };

  return (
    <AppDrawer formParams={formParams}>
      <form onSubmit={form.handleSubmit(handleSave)}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            mb: '16px',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <Field
              label={t('Name')}
              errorMessage={form.formState.errors.name?.message}
            >
              <TextField
                size="small"
                error={!!form.formState.errors.name}
                {...form.register('name')}
                fullWidth
              />
            </Field>
            <Field
              label={t('Email')}
              errorMessage={form.formState.errors.email?.message}
              helperText={t('Changing email address is not available yet')}
            >
              <TextField
                size="small"
                type="email"
                {...form.register('email')}
                fullWidth
                disabled
              />
            </Field>
          </Box>
          <LoadingButton
            loading={updateUserMutation.isPending}
            fullWidth
            variant="contained"
            type="submit"
          >
            {t('Save')}
          </LoadingButton>
        </Box>
      </form>
    </AppDrawer>
  );
};

export default UserProfileEditDrawer;
