import { Theme } from '@mui/material/styles';

export const createToastStyle = (theme: Theme) => ({
  style: {
    background: theme.palette.background.paper,
    color: theme.palette.text.primary,
    borderRadius: '100px',
    padding: '12px 24px',
  },
  duration: 2000,
} as const);
