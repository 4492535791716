import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useUserContext } from '../../contexts/UserContext';
import AppCardList from '../AppCardList';
import { useState } from 'react';
import LanguagePickerDrawer from './LanguagePickerDrawer';
import TimezonePickerDrawer from './TimezonePickerDrawer';
import ThemePickerDrawer from './ThemePickerDrawer';

type Props = {};

const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const SettingsInterface: React.FC<Props> = () => {
  const { user } = useUserContext();
  const [isLanguagePickerOpen, setIsLanguagePickerOpen] = useState(false);
  const [isTimezonePickerOpen, setIsTimezonePickerOpen] = useState(false);
  const [isThemePickerOpen, setIsThemePickerOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <Box>
      <AppCardList
        title={t('Interface')}
        items={[
          {
            title: t('Timezone'),
            value: t(user?.timezone ?? ''),
            onClick: () => setIsTimezonePickerOpen(true),
          },
          {
            title: t('Language'),
            value: t(user?.language!),
            onClick: () => setIsLanguagePickerOpen(true),
          },
          {
            title: t('Theme'),
            value: t(capitalizeFirstLetter(user?.themeMode ?? 'System')),
            onClick: () => setIsThemePickerOpen(true),
          },
        ]}
      ></AppCardList>

      <LanguagePickerDrawer
        formParams={{
          open: isLanguagePickerOpen,
          onClose: () => setIsLanguagePickerOpen(false),
        }}
      />

      <TimezonePickerDrawer
        formParams={{
          open: isTimezonePickerOpen,
          onClose: () => setIsTimezonePickerOpen(false),
        }}
        value={user?.timezone ?? null}
      />

      <ThemePickerDrawer
        formParams={{
          open: isThemePickerOpen,
          onClose: () => setIsThemePickerOpen(false),
        }}
      />
    </Box>
  );
};

export default SettingsInterface;
