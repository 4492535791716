import React from 'react';
import { FormParams } from '../../types';
import YesNoPickerDrawer from '../YesNoPickerDrawer';
import { useTranslation } from 'react-i18next';
import useExperience from '../../queryHelpers/useExperience';
import useUpdateExperience from '../../queryHelpers/useUpdateExperience';

type Props = {
  formParams: FormParams;
  experienceId: string;
};

const EnableGoogleMeetYesNoDrawer: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const experienceQuery = useExperience({
    id: props.experienceId,
    enabled: !!props.experienceId,
  });
  const updateExperience = useUpdateExperience(props.experienceId);

  const handleSelect = async (value: boolean) => {
    try {
      await updateExperience.mutateAsync({
        googleCalendarSettings: {
          ...experienceQuery.data?.googleCalendarSettings!,
          createGoogleMeetLink: value,
        },
      });
      return { error: undefined }; // Return an object with error if needed
    } catch (error) {
      return { error: t('Unable to update Google Meet settings') };
    }
  };

  return (
    <YesNoPickerDrawer
      formParams={props.formParams}
      onSelect={handleSelect}
      text={t(
        'Create Google Meet link for video calls in Google Calendar for each appointment',
      )}
    />
  );
};

export default EnableGoogleMeetYesNoDrawer;
