import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import AppDrawer from '../AppDrawer';
import { QRCode } from 'react-qrcode-logo';
import { Box, Button, Typography } from '@mui/material';
import { FormParams } from '../../types';

interface Props {
  formParams: FormParams;
  experienceLink: string;
}

const QRCodeDisplayDrawer: React.FC<Props> = ({
  formParams,
  experienceLink,
}) => {
  const { t } = useTranslation();
  const qrCodeRef = useRef<HTMLDivElement>(null);

  const handleShare = async () => {
    if (qrCodeRef.current) {
      const canvas = qrCodeRef.current.querySelector('canvas');
      if (canvas) {
        const imageBlob = await new Promise<Blob>((resolve) => {
          canvas.toBlob((blob) => resolve(blob!), 'image/png');
        });

        const shareData = {
          title: t('QR Code'),
          files: [new File([imageBlob], 'qr-code.png', { type: 'image/png' })],
        };

        try {
          await navigator.share(shareData);
        } catch (error) {
          console.error('Error sharing:', error);
        }
      }
    }
  };

  return (
    <AppDrawer
      formParams={formParams}
      title={t('QR code for making an appointment')}
      description={t(
        'QR Code.Description',
      )}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          mb: '16px',

        }}
      >
        <Box
          sx={{
            pt: '32px',
            pb: '24px',
            px: '16px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '16px',
            background: (theme) => theme.palette.background.paper,
            borderRadius: 2,
          }}
        >
          <Box ref={qrCodeRef} sx={{ borderRadius: 2 }}>
            <QRCode
              value={experienceLink}
              removeQrCodeBehindLogo
              eyeRadius={1}
              size={200}
              qrStyle="squares"
              logoImage="/images/logo.svg"
              logoPaddingStyle="circle"
              logoPadding={2}
              logoWidth={40}
              logoHeight={40}
            />
          </Box>

          <Typography sx={{ wordBreak: 'break-word', textAlign: 'center' }}>{experienceLink}</Typography>
        </Box>

        <Button variant="contained" fullWidth onClick={handleShare}>
          {t('Send QR code')}
        </Button>
      </Box>
    </AppDrawer>
  );
};

export default QRCodeDisplayDrawer;
