import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormParams } from '../../types';
import TransparentPickerDrawer from '../TransparentPickerDrawer';
import useDeleteBooking from '../../queryHelpers/useDeleteBooking';
import useBooking from '../../queryHelpers/useBooking';

interface Props {
  formParams: FormParams;
  bookingId: string | null;
  onCancel: () => void;
}

const CancelBookingsConfirmationDrawer: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const bookingQuery = useBooking(props.bookingId ?? undefined);
  const deleteBookingMutation = useDeleteBooking({
    booking: bookingQuery.data!,
  });

  const handleSelect = async (_: string) => {
    await deleteBookingMutation.mutateAsync(undefined);
    props.onCancel();
    return { error: undefined };
  };

  return (
    <TransparentPickerDrawer
      formParams={props.formParams}
      description={t('Cancel appointment permanently?')}
      options={[
        {
          text: t('Cancel appointment'),
          value: 'cancel',
          isError: true,
        },
      ]}
      onSelect={handleSelect}
    />
  );
};

export default CancelBookingsConfirmationDrawer;
