import { createContext, useContext, ReactNode } from 'react';
import { SxProps, Theme } from '@mui/material';

export type AppDrawerContextType = {
  paperProps?: {
    sx?: SxProps<Theme>;
  };
};

export const AppDrawerContext = createContext<AppDrawerContextType>({});

export const useAppDrawerContext = () => useContext(AppDrawerContext);

export const AppDrawerProvider = ({
  children,
  value,
}: {
  children: ReactNode;
  value: AppDrawerContextType;
}) => {
  return (
    <AppDrawerContext.Provider value={value}>{children}</AppDrawerContext.Provider>
  );
};
