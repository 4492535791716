import React, { useState, useRef } from 'react';
import { Box, Typography, Badge, Popover, styled, IconButton } from '@mui/material';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DateTime } from 'luxon';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useLanguage } from '../../contexts/LanguageContex';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { useTranslation } from 'react-i18next';

interface WeekCalendarToolbarProps {
  currentDate: DateTime;
  onSelectDay: (date: DateTime) => void;
  bookings?: Record<string, number>; // ISO date string -> booking count
  unviewedBookings?: Record<string, number>; // ISO date string -> unviewed booking count
}

const WeekContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.background.default,
  touchAction: 'pan-y', // Allow vertical scrolling but capture horizontal gestures
  maxWidth: '800px', // Add max-width for larger screens
  margin: '0 auto', // Center the container
}));

const HeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(0, 0, 2),
  maxWidth: '800px', // Add max-width for larger screens
  margin: '0 auto', // Center the container
}));

const DaysRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0.5, 0),
  color: theme.palette.text.primary,
  gap: theme.spacing(1), // Add gap between days
}));

const DayColumn = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  flex: 1, // Make columns flex evenly
  minWidth: '40px', // Keep minimum width for mobile
}));

const DayCircle = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'isSelected' && prop !== 'isToday',
})<{ isSelected?: boolean; isToday?: boolean }>(({ theme, isSelected, isToday }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '36px',
  height: '36px',
  borderRadius: '50%',
  marginTop: theme.spacing(0.5),
  backgroundColor: isSelected
    ? theme.palette.primary.main
    : 'transparent',
  border: isToday && !isSelected
    ? `2px solid ${theme.palette.primary.main}`
    : 'none',
  color: isSelected
    ? theme.palette.primary.contrastText
    : theme.palette.text.primary,
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: isSelected
      ? theme.palette.primary.main
      : theme.palette.action.hover,
  },
}));

const WeekCalendarToolbar: React.FC<WeekCalendarToolbarProps> = ({
  currentDate,
  onSelectDay,
  bookings = {},
  unviewedBookings = {},
}) => {
  const { dateLocale } = useLanguage();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [touchStartX, setTouchStartX] = useState<number | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const handleDateRangeClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCalendarClose = () => {
    setAnchorEl(null);
  };

  const handleCalendarDateChange = (date: DateTime) => {
    onSelectDay(date);
    handleCalendarClose();
  };

  const handleDayClick = (day: DateTime) => {
    onSelectDay(day);
  };

  const handleTouchStart = (e: React.TouchEvent) => {
    setTouchStartX(e.touches[0].clientX);
  };

  const handleTouchEnd = (e: React.TouchEvent) => {
    if (touchStartX === null) return;

    const touchEndX = e.changedTouches[0].clientX;
    const diffX = touchEndX - touchStartX;

    // If the swipe is significant enough (more than 50px)
    if (Math.abs(diffX) > 50) {
      // Clone the current date to avoid mutation
      const dayOfWeek = currentDate.weekday;

      if (diffX > 0) {
        // Swipe right - go to previous week
        const newDate = currentDate.minus({ weeks: 1 }).set({ weekday: dayOfWeek as 1|2|3|4|5|6|7 });
        onSelectDay(newDate);
      } else {
        // Swipe left - go to next week
        const newDate = currentDate.plus({ weeks: 1 }).set({ weekday: dayOfWeek as 1|2|3|4|5|6|7 });
        onSelectDay(newDate);
      }
    }

    setTouchStartX(null);
  };

  const navigateToPreviousWeek = () => {
    const dayOfWeek = currentDate.weekday;
    const newDate = currentDate.minus({ weeks: 1 }).set({ weekday: dayOfWeek as 1|2|3|4|5|6|7 });
    onSelectDay(newDate);
  };

  const navigateToNextWeek = () => {
    const dayOfWeek = currentDate.weekday;
    const newDate = currentDate.plus({ weeks: 1 }).set({ weekday: dayOfWeek as 1|2|3|4|5|6|7 });
    onSelectDay(newDate);
  };

  // Generate current week days
  const weekStart = currentDate.startOf('week');
  const weekDays = Array.from({ length: 7 }, (_, i) =>
    weekStart.plus({ days: i })
  );

  return (
    <Box sx={{ width: '100%' }}>
      <HeaderContainer>
        <IconButton
          onClick={navigateToPreviousWeek}
          aria-label="Previous week"
          sx={{ padding: { xs: 1, md: 2 } }} // Smaller padding on mobile
        >
          <ChevronLeftIcon />
        </IconButton>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            padding: '0 8px',
            flex: 1,
            justifyContent: 'center',
          }}
          onClick={handleDateRangeClick}
        >
          <Typography
            variant="h6"
            component="span"
            sx={{
              fontSize: { xs: '0.9rem', sm: '1.25rem' }, // Smaller font on mobile
              textAlign: 'center',
            }}
          >
            {(() => {
              // Get the first and last day of the week
              const firstDay = weekStart;
              const lastDay = weekStart.plus({ days: 6 });

              // If the week spans two months
              if (firstDay.month !== lastDay.month) {
                return `${firstDay.toFormat('d LLL')} - ${lastDay.toFormat('d LLL, yyyy')}`;
              }

              // If the week spans two years
              if (firstDay.year !== lastDay.year) {
                return `${firstDay.toFormat('d LLL, yyyy')} - ${lastDay.toFormat('d LLL, yyyy')}`;
              }

              // Same month and year
              return `${firstDay.toFormat('d')} - ${lastDay.toFormat('d LLL, yyyy')}`;
            })()}
          </Typography>
          <ArrowDropDownIcon />
        </Box>

        <IconButton
          onClick={navigateToNextWeek}
          aria-label={t('Next week')}
          sx={{ padding: { xs: 1, md: 2 } }}
        >
          <ChevronRightIcon />
        </IconButton>
      </HeaderContainer>

      <WeekContainer
        ref={containerRef}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
      >
        <DaysRow>
          {[t('Mon.short.weekday'), t('Tue.short.weekday'), t('Wed.short.weekday'), t('Thu.short.weekday'), t('Fri.short.weekday'), t('Sat.short.weekday'), t('Sun.short.weekday')].map((dayName, index) => (
            <Typography
              key={index}
              sx={{
                flex: 1,
                textAlign: 'center',
                color: index === 5 || index === 6 ? 'red' : 'inherit', // Red for Saturday and Sunday
                fontSize: { xs: '0.875rem', sm: '1rem' } // Responsive font size
              }}
            >
              {dayName}
            </Typography>
          ))}
        </DaysRow>

        <DaysRow>
          {weekDays.map((day) => {
            const isToday = day.hasSame(DateTime.now(), 'day');
            const isSelected = day.hasSame(currentDate, 'day');
            const dateStr = day.toISODate();
            const hasBookings = bookings[dateStr!] && bookings[dateStr!] > 0;
            const unviewedCount = unviewedBookings?.[dateStr!] || 0;

            return (
              <DayColumn key={day.toISODate()}>
                {hasBookings ? (
                  <Badge
                    color={unviewedCount > 0 ? "secondary" : "primary"}
                    badgeContent={unviewedCount > 0 ? `+${unviewedCount}` : bookings[dateStr!]}
                    sx={{
                      '& .MuiBadge-badge': {
                        right: -3,
                        top: 3,
                        fontSize: '0.7rem',
                      }
                    }}
                  >
                    <DayCircle
                      isSelected={isSelected}
                      isToday={isToday}
                      onClick={() => handleDayClick(day)}
                    >
                      <Typography sx={{ fontSize: { xs: '0.875rem', sm: '1rem' } }}>
                        {day.toFormat('d')}
                      </Typography>
                    </DayCircle>
                  </Badge>
                ) : (
                  <DayCircle
                    isSelected={isSelected}
                    isToday={isToday}
                    onClick={() => handleDayClick(day)}
                  >
                    <Typography sx={{ fontSize: { xs: '0.875rem', sm: '1rem' } }}>
                      {day.toFormat('d')}
                    </Typography>
                  </DayCircle>
                )}
              </DayColumn>
            );
          })}
        </DaysRow>
      </WeekContainer>

      {/* Calendar Popup */}
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleCalendarClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{
          marginTop: 1, // Add a small margin from the date range
        }}
      >
        <DateCalendar
          value={currentDate.toJSDate()}
          onChange={(date) => {
            if (date) {
              handleCalendarDateChange(DateTime.fromJSDate(date));
            }
          }}
          // @ts-ignore - localization issue with MUI DateCalendar
          locale={dateLocale.code}
          slots={{
            day: (dayProps: PickersDayProps<Date>) => {
              const dateStr = DateTime.fromJSDate(dayProps.day).toISODate();
              const bookingCount = bookings[dateStr!] || 0;
              const unviewedCount = unviewedBookings?.[dateStr!] || 0;

              return (
                <Badge
                  color={unviewedCount > 0 ? "secondary" : "primary"}
                  badgeContent={bookingCount > 0 && !dayProps.outsideCurrentMonth ?
                    (unviewedCount > 0 ? `+${unviewedCount}` : bookingCount) : null}
                  overlap="circular"
                  sx={{
                    '& .MuiBadge-badge': {
                      right: -3,
                      top: 3,
                      fontSize: '0.7rem',
                    }
                  }}
                >
                  <PickersDay {...dayProps} />
                </Badge>
              );
            }
          }}
        />
      </Popover>
    </Box>
  );
};

export default WeekCalendarToolbar;
