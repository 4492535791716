import { Done } from '@mui/icons-material';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

type ItemProps = {
  title: string;
  description: string;
};

const Item: React.FC<ItemProps> = (props) => {
  return (
    <ListItem>
      <ListItemIcon sx={{ fontSize: 25 }}>
        <Done color="primary" />
      </ListItemIcon>
      <ListItemText
        primary={props.title}
        secondary={props.description}
      ></ListItemText>
    </ListItem>
  );
};

const FeaturesSection = () => {
  const { t } = useTranslation();
  const items: Omit<ItemProps, 'imagePosition'>[] = [
    {
      title: t('Online recording'),
      description: t('Online recording.Description'),
    },
    {
      title: t('Personal link'),
      description: t('Personal link.Description'),
    },
    {
      title: t('Online notification'),
      description: t('Online notification.Description'),
    },
    {
      title: t('Individual schedule'),
      description: t('Individual schedule.Description'),
    },
    {
      title: t('Special conditions for recording'),
      description: t('Special conditions for recording.Description'),
    },
    {
      title: t('Calendar synchronization'),
      description: t('Calendar synchronization.Description'),
    },
  ];

  return (
    <div className="w-full px-6 flex flex-col space-y-6 justify-center items-center text-center">
      <Typography variant="h4">{t('Functions of Minutus')}</Typography>
      <div>
        {items.map((item, index) => (
          <Item key={index} title={item.title} description={item.description} />
        ))}
      </div>
    </div>
  );
};

export default FeaturesSection;
