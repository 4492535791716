import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import useBooking from '../../queryHelpers/useBooking';
import useExperience from '../../queryHelpers/useExperience';
import { useTimeZone } from '../../contexts/TimeZoneContext';
import { useTranslation } from 'react-i18next';
import AppCard from '../../components/AppCard';
import AppTransparentCard from '../../components/AppTransparentCard';
import AppCardButton from '../../components/AppCardButton';
import AppErrorButton from '../../components/AppErrorButton';
import CancelBookingsConfirmationDrawer from '../../components/bookings/CancelBookingsConfirmationDrawer';
import { routes } from '../../routes';
import { CongratsModal } from './CongratsModal';
import { AddToCalendarButton } from '../../components/bookingForm/AddToCalendarButton';
import AddBookmarkCard from '../../components/bookingForm/AddBookmarkCard';
import BookingScheduleEditDrawer from '../../components/bookings/BookingScheduleEditDrawer';

interface AppointmentConfirmationProps {}
const BookingConfirmationPage: React.FC<AppointmentConfirmationProps> = () => {
  const { t } = useTranslation();
  const { timezone } = useTimeZone();
  const { bookingId } = useParams<{ bookingId: string }>();
  const bookingQuery = useBooking(bookingId);
  const navigate = useNavigate();
  const experienceQuery = useExperience({
    id: bookingQuery.data?.experienceId as string,
    enabled: !!bookingQuery.data,
  });

  const [isCancelConfirmationOpen, setIsCancelConfirmationOpen] =
    useState(false);
  const [isCongratsModalOpen, setIsCongratsModalOpen] = useState(false);
  const [isScheduleEditOpen, setIsScheduleEditOpen] = useState(false);

  useEffect(() => {
    // Check if it's the first launch (you can use localStorage or a similar method)
    const isFirstLaunch =
      localStorage.getItem(`firstLaunch_${bookingId}`) === null;
    if (isFirstLaunch) {
      setIsCongratsModalOpen(true);
      localStorage.setItem(`firstLaunch_${bookingId}`, 'false'); // Set to false after first launch
    }
  }, [bookingId]);

  const handleCancelConfirmation = () => {
    console.log('Appointment cancelled');
    setIsCancelConfirmationOpen(false);
  };

  const handleCreateAnotherAppointment = () => {
    navigate(routes.booking(experienceQuery.data?.id));
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        mb: '16px',
      }}
    >
      {bookingQuery.data && experienceQuery.data ? (
        <>
          <AppTransparentCard
            title={experienceQuery.data.summary.title}
            badge={bookingQuery.data.cancelledAt ? {
              text: t('Cancelled'),
              color: 'error'
            } : undefined}
          >
            <Typography variant="body1">
              {experienceQuery.data.summary.description}
            </Typography>
          </AppTransparentCard>
          <AppCard
            title={t('Date and time')}
            onClick={bookingQuery.data.cancelledAt ? undefined : () => setIsScheduleEditOpen(true)}
          >
            <Typography variant="body1">
              {bookingQuery.data?.timeSlot.start
                .setZone(timezone)
                .toFormat('dd MMMM')}{' '}
              (
              {bookingQuery.data?.timeSlot.start
                .setZone(timezone)
                .toFormat('HH:mm')}{' '}
              -{' '}
              {bookingQuery.data?.timeSlot.end
                .setZone(timezone)
                .toFormat('HH:mm')}
              )
            </Typography>
          </AppCard>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
            <AppCardButton
              iconPath={'/images/plus.svg'}
              text={t('Create another appointment')}
              onClick={handleCreateAnotherAppointment}
            />
            <AddToCalendarButton
              bookingId={bookingQuery.data.id!}
            />
          </Box>

          <AppTransparentCard title={t('Your details')}>
            <Typography variant="body1">{bookingQuery.data?.name}</Typography>
            <Typography variant="body1">{bookingQuery.data?.email}</Typography>
            <Typography variant="body1">{bookingQuery.data?.phone}</Typography>
          </AppTransparentCard>

          <AddBookmarkCard pageTitle={experienceQuery.data.summary.title} />

          <AppErrorButton
            text={t('Cancel appointment')}
            onClick={() => setIsCancelConfirmationOpen(true)}
            disabled={!!bookingQuery.data.cancelledAt}
          />

          <CongratsModal
            formParams={{
              open: isCongratsModalOpen,
              onClose: () => setIsCongratsModalOpen(false),
            }}
          />

          <CancelBookingsConfirmationDrawer
            formParams={{
              open: isCancelConfirmationOpen,
              onClose: () => setIsCancelConfirmationOpen(false),
            }}
            bookingId={bookingId ?? null}
            onCancel={handleCancelConfirmation}
          />

          <BookingScheduleEditDrawer
            formParams={{
              open: isScheduleEditOpen,
              onClose: () => setIsScheduleEditOpen(false),
            }}
            bookingId={bookingId ?? null}
          />
        </>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default BookingConfirmationPage;
