import { useMemo } from 'react';
import { FormParams } from '../../types';
import { useTranslation } from 'react-i18next';
import { PickerOption } from '../PickerDrawer';
import useUpdateUserSettings from '../../queryHelpers/useUpdateUserSettings';
import TransparentPickerDrawer from '../TransparentPickerDrawer';
import { useUserContext } from '../../contexts/UserContext';
import { ThemeMode } from 'shared-ts/entities';

interface Props {
  formParams: FormParams;
}

const ThemePickerDrawer: React.FC<Props> = ({ formParams }) => {
  const { t } = useTranslation();
  const { user } = useUserContext();
  const updateUserMutation = useUpdateUserSettings();

  const options = useMemo(() => {
    return [
      { text: t('Light'), value: 'light' as ThemeMode },
      { text: t('Dark'), value: 'dark' as ThemeMode },
      { text: t('System'), value: 'system' as ThemeMode },
    ] as PickerOption<ThemeMode>[];
  }, [t]);

  const handleSelect = async (themeMode: ThemeMode) => {
    try {
      await updateUserMutation.mutateAsync({
        id: user!.id,
        themeMode,
      });
      return {};
    } catch (error) {
      return {
        error: t('Failed to update theme'),
      };
    }
  };

  return (
    <TransparentPickerDrawer
      formParams={formParams}
      description={t('Select theme')}
      options={options}
      onSelect={handleSelect}
    />
  );
};

export default ThemePickerDrawer;
