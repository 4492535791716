import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import AppDrawer from '../AppDrawer';
import { FormParams } from '../../types';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import React from 'react';
import { useTimeZone } from '../../contexts/TimeZoneContext';
import { useEditForm } from '../../hooks/useEditForm';
import useBooking from '../../queryHelpers/useBooking';
import useUpsertBooking from '../../queryHelpers/useUpsertBooking';
import { LoadingButton } from '@mui/lab';
import { DateTimeSlotVM } from '../../viewModels';
import { Controller } from 'react-hook-form';
import * as yup from 'yup';
import TimeSlotSelector from '../bookingForm/TimeSlotSelector';
import useExperience from '../../queryHelpers/useExperience';
import useTimeSlotsForSelection from '../../hooks/useTimeSlotsForSelection';
import { FormHelperText } from '@mui/material';
import { DayCalendarSelector } from '../experiences/scheduleEdit/DayCalendarSelector';

interface ScheduleEditDrawerProps {
  formParams: FormParams;
  bookingId: string | null;
}

const BookingScheduleFormSchema = yup
  .object({
    timeSlot: yup.object<DateTimeSlotVM>().required('Select time'),
  })
  .required();

type BookingScheduleFormInputs = {
  timeSlot: DateTimeSlotVM;
};

const BookingScheduleEditDrawer: React.FC<ScheduleEditDrawerProps> = (
  props,
) => {
  const { t } = useTranslation();
  const { timezone } = useTimeZone();
  const bookingQuery = useBooking(props.bookingId ?? undefined);
  const upsertBookingMutation = useUpsertBooking();

  const [selectedDate, setSelectedDate] = useState<DateTime | undefined>(() => {
    if (bookingQuery.data) {
      return bookingQuery.data.timeSlot.start
        .setZone(timezone)
        .setZone(DateTime.now().zone, { keepLocalTime: true });
    }
    return DateTime.now().startOf('day').setZone(timezone);
  });

  const { form } = useEditForm<BookingScheduleFormInputs>({
    defaultValues: {
      timeSlot: bookingQuery.data?.timeSlot,
    },
    formSchema: BookingScheduleFormSchema,
  });

  useEffect(() => {
    if (bookingQuery.data) {
      form.reset({
        timeSlot: bookingQuery.data.timeSlot,
      });
    }
  }, [bookingQuery.data, form]);

  const experienceQuery = useExperience({
    id: bookingQuery.data?.experienceId ?? '',
    link: bookingQuery.data?.experienceId,
  });

  const timeSlotsForSelection = useTimeSlotsForSelection(
    selectedDate ?? null,
    experienceQuery.data,
    bookingQuery.data,
    timezone,
  );

  const onSubmit = async (data: BookingScheduleFormInputs) => {
    await upsertBookingMutation.mutateAsync({
      ...bookingQuery.data!,
      ...data,
      id: props.bookingId!,
    });
    props.formParams.onClose();
  };

  return (
    <AppDrawer
      title={t('Schedule')}
      formParams={props.formParams}
      notScrollable
    >
        <form
          onSubmit={form?.handleSubmit(onSubmit)}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
              pb: '16px',
            }}
          >
            <Box>
              <DayCalendarSelector
                availabilities={timeSlotsForSelection ?? []}
                value={selectedDate}
                onChange={(value) => setSelectedDate(value)}
                disableEmptyDates={true}
              />
            </Box>

            {/* <Box
              sx={{
                mb: '16px',
                overflowY: 'auto',
                height: '100px',
                maxHeight: '100px',
              }}
            > */}
              <Controller
                name="timeSlot"
                control={form.control}
                render={({ field }) => (
                  <TimeSlotSelector
                    slots={timeSlotsForSelection?.filter(
                      (x) =>
                        selectedDate && x.start.hasSame(selectedDate, 'day'),
                    )}
                    value={field.value}
                    onChange={(value) => field.onChange(value)}
                  />
                )}
              />
              {form.formState.errors.timeSlot && (
                <FormHelperText error>
                  {form.formState.errors.timeSlot?.message}
                </FormHelperText>
              )}
            {/* </Box> */}
            <LoadingButton
              variant="contained"
              color="primary"
              type="submit"
              loading={upsertBookingMutation.isPending}
            >
              {t('Re-schedule appointment')}
            </LoadingButton>
          </Box>
        </form>
    </AppDrawer>
  );
};

export default BookingScheduleEditDrawer;
