// src/routes.ts
export const routes = {
  landing: '/',
  onboard: '/onboard',
  admin: {
    index: '/admin',
    bookings: '/admin/bookings',
    bookingDetail: (id: string = ':id') => `/admin/bookings/${id}`,
    experiences: '/admin/experiences',
    experienceDetail: (id: string = ':id') => `/admin/experiences/${id}`,
    newExperience: '/admin/experiences/new',
    settings: '/admin/settings',
  },
  login: '/login',
  bookingConfirmation: (bookingId: string = ':bookingId') =>
    `/booking/${bookingId}/success`,
  booking: (experienceId: string = ':experienceId') => `/book/${experienceId}`,
};
