import React, { useEffect, useState } from 'react';
import { useUserContext } from './UserContext';
import { ThemeProvider } from '@mui/material/styles';
import darkTheme from '../theme/dark';
import lightTheme from '../theme/light';

// Define the global function type
declare global {
  interface Window {
    updateThemeColorManually?: (theme: 'light' | 'dark') => void;
  }
}

export const AppThemeProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { user } = useUserContext();
  const [currentTheme, setCurrentTheme] = useState<'light' | 'dark'>('light');
  const themeMode = user?.themeMode || 'light';

  useEffect(() => {
    console.log('themeMode', themeMode);
    const updateTheme = () => {
      if (window.location.pathname === '/') {
        setCurrentTheme('light');
      }
      else if (themeMode === 'system') {
        const prefersDark = window.matchMedia(
          '(prefers-color-scheme: dark)',
        ).matches;
        setCurrentTheme(prefersDark ? 'dark' : 'light');
      } else {
        setCurrentTheme(themeMode);
      }
    };

    updateTheme();

    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    mediaQuery.addEventListener('change', updateTheme);

    return () => mediaQuery.removeEventListener('change', updateTheme);
  }, [themeMode]);

  // Update theme-color meta tag when theme changes
  useEffect(() => {
    // Call the global function if it exists
    if (window.updateThemeColorManually) {
      window.updateThemeColorManually(currentTheme);
    }
  }, [currentTheme]);

  return (
    <ThemeProvider theme={currentTheme === 'dark' ? darkTheme : lightTheme}>
      {children}
    </ThemeProvider>
  );
};
