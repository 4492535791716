import { Box, Typography, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AppDrawer from './AppDrawer';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface LinkInputDrawerProps {
  formParams: any; // Adjust type as necessary for formParams
  title: string;
  value?: string;
  onSave: (link: string) => Promise<{ error?: string }>;
  confirmText: string;
}

const LinkInputDrawer: React.FC<LinkInputDrawerProps> = ({
  formParams,
  title,
  value,
  onSave,
  confirmText,
}) => {
  const [link, setLink] = useState(value ?? '');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    setLink(value ?? '');
  }, [value]);

  const handleSave = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const result = await onSave(link);
      if (result.error) {
        setError(result.error);
      } else {
        formParams.onClose();
      }
    } catch (err) {
      setError('Failed to save link');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AppDrawer
      formParams={formParams}
      title={title}
      description={t('Create a beautiful link address and share it with clients for booking')}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          // gap: '24px',
          mb: '16px',
        }}
      >
        <Box sx={{ my: '32px' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <Typography
              sx={{
                fontSize: '16px',
              }}
            >
              minutus.pro/book/
            </Typography>
            <TextField
              placeholder="Введите ссылку"
              value={link}
              size="small"
              onChange={(e) => setLink(e.target.value)}
              fullWidth
            />
          </Box>

          {error && (
            <Typography sx={{ color: 'error.main', textAlign: 'center' }}>
              {error}
            </Typography>
          )}
        </Box>
        <LoadingButton
          type="submit"
          variant="contained"
          onClick={handleSave}
          loading={isLoading}
          fullWidth
        >
          {confirmText}
        </LoadingButton>
      </Box>
    </AppDrawer>
  );
};

export default LinkInputDrawer;
