import { Chair, Savings, Speed } from '@mui/icons-material';
import { Box, Card, CardContent, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

type BenefitsSectionItemProps = {
  title: string;
  description: string;
  icon: React.ReactNode;
};

const BenefitsSectionItem: React.FC<BenefitsSectionItemProps> = (props) => {
  return (
    <Card sx={{ width: 300 }} elevation={0}>
      <CardContent className="flex flex-col items-center justify-center text-center m-4 gap-2">
        <Box className="align-top" sx={{ color: 'primary.main', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {React.cloneElement(props.icon as React.ReactElement, {
            sx: { fontSize: 64 }
          })}
        </Box>
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          className="text-center"
          color="primary"
        >
          {props.title}
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          className="text-center"
        >
          {props.description}
        </Typography>
      </CardContent>
    </Card>
  );
};

const BenefitsSection = () => {
  const { t } = useTranslation();
  const items: BenefitsSectionItemProps[] = [
    {
      title: t('Convenient'),
      description: t('Convenient.Description'),
      icon: <Chair />,
    },
    {
      title: t('Quick'),
      description: t('Quick.Description'),
      icon: <Speed />,
    },
    {
      title: t('Profitable'),
      description: t('Profitable.Description'),
      icon: <Savings />,
    },
  ];

  return (
    <div className="w-full flex flex-col space-y-6 justify-center items-center text-center">
      <Typography variant="h4">
        {t('Benefits of Minutus')}
      </Typography>
      <div className="flex flex-row flex-wrap justify-center gap-6 md:gap-6">
        {items.map((item) => (
          <BenefitsSectionItem
            key={item.title}
            title={item.title}
            description={item.description}
            icon={item.icon}
          />
        ))}
      </div>
    </div>
  );
};

export default BenefitsSection;
