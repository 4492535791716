import { Box, CardProps, Typography, Chip } from '@mui/material';
import {
  MouseEventHandler,
  PropsWithChildren,
  ReactElement,
  useState,
} from 'react';

type BadgeProps = {
  text: string;
  color?: 'error' | 'warning' | 'info' | 'success';
};

type AppTransparentCardProps = PropsWithChildren & {
  id?: string;
  cardProps?: CardProps;
  title?: string | ReactElement;
  onClick?: MouseEventHandler<HTMLDivElement> | undefined;
  badge?: BadgeProps;
};

const AppTransparentCard: React.FC<AppTransparentCardProps> = (props) => {
  const [isClicked, setIsClicked] = useState(false);

  const clickableCardProps: CardProps = {
    onMouseDown: () => setIsClicked(true),
    onMouseUp: () => setIsClicked(false),
    onMouseLeave: () => setIsClicked(false),
    onTouchStart: () => setIsClicked(true),
    onTouchEnd: () => setIsClicked(false),
    sx: {
      transform: isClicked ? 'scale(0.99)' : 'scale(1)',
      transition: 'transform 0.1s',
      cursor: 'pointer',
    },
    onClick: props.onClick,
  };

  return (
    <Box
      id={props.id}
      {...(props.onClick && clickableCardProps)}
      sx={{
        padding: (theme) =>
          (theme.components?.MuiCardContent?.styleOverrides?.root as any)
            .padding,
        ...(props.onClick && clickableCardProps.sx),
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
          {props.title && (
            <Typography variant="cardHeader">{props.title}</Typography>
          )}
          {props.badge && (
            <Chip
              label={props.badge.text}
              color={props.badge.color || 'default'}
              size="small"
            />
          )}
        </Box>
        <Box>{props.children}</Box>
      </Box>
    </Box>
  );
};

export default AppTransparentCard;
