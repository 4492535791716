import { MouseEventHandler } from 'react';
import { Property } from 'csstype';
import { LoadingButton } from '@mui/lab';

type AppErrorButtonProps = {
  text: string;
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
  color?: Property.Color
  fullWidth?: boolean;
  disableTopRadius?: boolean;
  disableBottomRadius?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
};

const AppErrorButton: React.FC<AppErrorButtonProps> = (props) => {
  return (
    <LoadingButton
      disableFocusRipple
      sx={{
        textTransform: 'none',
        backgroundColor: (theme) =>
          (theme.components?.MuiCard?.styleOverrides?.root as any)
            .backgroundColor,
        color: (theme) => props.color ?? theme.palette.error.main,
        '&:hover': {
          backgroundColor: (theme) =>
            (theme.components?.MuiCard?.styleOverrides?.root as any)
              .backgroundColor,
        },
        borderTopLeftRadius: (theme) =>
          props.disableTopRadius ? '0px' : theme.shape.borderRadius,
        borderTopRightRadius: (theme) =>
          props.disableTopRadius ? '0px' : theme.shape.borderRadius,
        borderBottomLeftRadius: (theme) =>
          props.disableBottomRadius ? '0px' : theme.shape.borderRadius,
        borderBottomRightRadius: (theme) =>
          props.disableBottomRadius ? '0px' : theme.shape.borderRadius,
      }}
      fullWidth={props.fullWidth}
      onClick={props.onClick}
      disabled={props.disabled}
      loading={props.isLoading}
    >
      {props.text}
    </LoadingButton>
  );
};

export default AppErrorButton;
