import {
  Drawer,
  List,
  ListItemIcon,
  ListItemText,
  Box,
  Typography,
  Avatar,
  ListItemButton,
  Badge,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SettingsIcon from '@mui/icons-material/Settings';
import { WorkOutline } from '@mui/icons-material';
import { routes } from '../routes';
import { useTranslation } from 'react-i18next';
import { useUserContext } from '../contexts/UserContext';
import { useBookingCounts } from '../hooks/useBookingCounts';

export const DESKTOP_SIDEBAR_WIDTH = 240;

export function AdminSidebar() {
  const { t } = useTranslation();
  const { user } = useUserContext();
  const location = useLocation();
  const navigate = useNavigate();
  const { totalUnviewedCount } = useBookingCounts();

  const menuItems = [
    {
      label: t('Appointments'),
      icon: (
        <Badge
          color="secondary"
          badgeContent={totalUnviewedCount ? `+${totalUnviewedCount}` : null}
          sx={{
            '& .MuiBadge-badge': {
              fontSize: '0.7rem',
            }
          }}
        >
          <CalendarMonthIcon />
        </Badge>
      ),
      path: routes.admin.bookings,
    },
    {
      label: t('Services'),
      icon: <WorkOutline />,
      path: routes.admin.experiences,
    },
    {
      label: t('Settings'),
      icon: <SettingsIcon />,
      path: routes.admin.settings,
    },
  ];

  return (
    <Drawer
      variant="permanent"
      PaperProps={{
        sx: {
          gap: '8px',
          display: 'flex',
          flexDirection: 'column',
        },
      }}
      sx={{
        width: DESKTOP_SIDEBAR_WIDTH,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: DESKTOP_SIDEBAR_WIDTH,
          boxSizing: 'border-box',
          backgroundColor: 'background.default',
          borderRight: '1px solid',
          borderColor: 'divider',
          top: 'max(env(safe-area-inset-top), 0px)',
          height: '100%',
          bottom: 0,
        },
      }}
    >
      <Box
        sx={{
          p: 2,
          // backgroundColor: (theme) => theme.palette.background.paper,
          borderRadius: (theme) => `${theme.shape.borderRadius}px`,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ bgcolor: 'primary.main', mr: 2 }}>
            {user?.name.charAt(0)}
          </Avatar>
          <Box>
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: 500, color: 'text.secondary' }}
            >
              {user?.name}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box sx={{ overflow: 'auto' }}>
        <List sx={{ gap: '8px', display: 'flex', flexDirection: 'column' }}>
          {menuItems.map((item) => (
            <ListItemButton
              key={item.path}
              selected={location.pathname.includes(item.path)}
              onClick={() => navigate(item.path)}
              sx={{
                m: '2px',
                borderRadius: (theme) => `${theme.shape.borderRadius}px`,
                px: 2,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 40,
                  color: 'text.secondary',
                }}
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText
                primary={item.label}
                sx={{
                  '& .MuiTypography-root': {
                    fontSize: '0.95rem',
                    color: 'text.secondary'
                  },
                }}
              />
            </ListItemButton>
          ))}
        </List>
      </Box>
    </Drawer>
  );
}
