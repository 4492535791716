import React, { createContext, useContext, useEffect, ReactNode } from 'react';
import { Settings } from 'luxon';
import { useTimeZone } from './TimeZoneContext';
import { useLanguage } from './LanguageContex';

interface DateTimeContextType {
}

const DateTimeContext = createContext<DateTimeContextType | undefined>(
  undefined,
);

export const DateTimeProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { timezone } = useTimeZone();
  const { dateLocale } = useLanguage();

  useEffect(() => {
    // Set global Luxon settings
    Settings.defaultZone = timezone;
    if (dateLocale.code) {
      Settings.defaultLocale = dateLocale.code;
    }
  }, [timezone, dateLocale]);

  return (
    <DateTimeContext.Provider value={undefined}>
      {children}
    </DateTimeContext.Provider>
  );
};

export const useDateTime = () => {
  const context = useContext(DateTimeContext);
  if (context === undefined) {
    throw new Error('useDateTime must be used within a DateTimeProvider');
  }
  return context;
};
